* {
  font-family: var(--font-family);
}

body {
  font: var(--small-text);
  color: var(--text-color);
}

input,
select,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font: inherit;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
}

input[type=number] {
  -moz-appearance: textfield;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: var(--text-color);
}

h1 {
  font-size: 1.8rem;
  line-height: 1.14;
  font-weight: normal;
}

h3 {
  font-size: 18px;
  line-height: 1.33;
}

h4 {
  font-size: 14px;
  line-height: 1.29;
}

h5 {
  font-size: 12px;
  line-height: 1.29;
}


h6 {
  font-size: 11px;
  line-height: 1.29;
}



input::-webkit-input-placeholder,
input::-moz-placeholder,
input:-moz-placeholder,
input:-ms-input-placeholder {
  color: var(--text-color) !important;
  opacity: 1 !important;
}

a {
  color: inherit;
}

.text-md {
  font: var(--medium-text);
}

.text-xs {
  font: var(--xs-text);
}

.title-medium {
  font: var(--medium-title);
}

.text-gray {
  color: var(--darkgray);
}

.text-success {
  color: var(--text-success-color)
}
.text-blue {
  color: var(--light-blue);
}
.text-error {
  color: var(--text-alert-color)
}
