.scoreChartContainer {
  position: relative;
  height: 470px
}
.gaugeChart {
  width: 450px;
  min-height: 430px;
  margin: 15px;
  padding: 0;
  box-sizing: border-box;
}
.volatilityScoreLabel {
  position: absolute;
  top: 210px;
  left: 221px;
  height: 35px;
  width: 35px;
  border: 2px solid #fff;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  padding: 11px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  background-color: #1c202e;
}
.indexScoreTooltipSpot {
  height: 40px;
  width: 270px;
  margin: auto auto 0 auto;
  position: absolute;
  left: 100px;
  bottom: 80px;
  background: transparent;
}

.bandColor1 {
  color: #41d9a0;
}
.bandColor2 {
  color: #00b370;
}
.bandColor3 {
  color: #d9bf41;
}
.bandColor4 {
  color: #fb9900;
}
.bandColor5 {
  color: #e17300;
}
.bandColor6 {
  color: #e34100;
}
.bandColor7 {
  color: #a50009;
}
