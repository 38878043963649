.tabsWrapper {

  width: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.tabsList {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 0;
  list-style: none;
  border-bottom: 1px solid var(--border-color);
}

.tabsItem {
  margin-right: 24px;
  padding-bottom: 5px;
  margin-bottom: -2px;
  line-height: 24px;
  border-bottom: 3px solid transparent;
  color: var(--tab-inactive-color);

  &.active {
    --tab-color: var(--tab-active-color);
    border-bottom: 3px solid var(--tab-active-color);
  }

}

.tabsBtn {
  color: var(--tab-inactive-color);

  font-family: Roboto;
  font-size: 21px;

  &.active {
    --tab-color: var(--tab-active-color);
    color: var(--tab-active-color);
  }

}

.tabsContent {
  display: flex;
  flex-grow: 1;
  margin: 20px 0;
  height: 100%;
  min-height: 1px;
  color: var(--content-text-color);

  &.contentWrapper {
    background: var(--content-background);
    padding: var(--content-paddings);
  }
}
