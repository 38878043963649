.noDataBlock {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 209px;
  padding-top: 207px;
  background: url('../../../../../../../../static/images/empty.svg') no-repeat;

  text-align: center;
}

.noDataText{
  margin-bottom: 20px;
  color: var(--darkgray);
}
