.sliderBlock {
  --title-color: var(--text-color);
}

.sliderBlockContent {
  display: flex;
  margin-top: 5px;
}

.sliderBlockSlider {
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.sliderBlockSliderValue {
  min-width: 60px;
  text-align: right;
}

.sliderHandlerAreaWrapper {
  position: absolute;
  width: 100%;
  height: 2px;
  overflow: hidden;
}

.sliderHandlerArea {
  position: relative;
  width: calc(var(--rc-handler-size) + var(--rc-handler-border-width) * 2);
  height: 100%;
  margin-left: -7px;

  &::before {
    content: '';
    width: 20px;
    height: 100%;
    position: absolute;
    left: -20px;
    background: linear-gradient(to right, transparent, var(--rc-handler-color));
  }

  &::after {
    content: '';
    position: absolute;
    width: 20px;
    height: 100%;
    right: -20px;
    background: linear-gradient(to left, transparent, var(--rc-handler-color));
  }
}
