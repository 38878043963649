.dateRangeField {
  margin: 0 !important;
  padding: 0.05rem 0.5rem;
  width: 100px;
}

.picker{
:global(.k-picker-wrap), :global(.k-dateinput-wrap){
  background-color: #161926 !important;
  border-radius: 3px;
}
:global(.k-dateinput-wrap){
  ::placeholder{
    color:white;
  }
}
:global(.k-icon){
  font-size:13px!important;
}

}

.dropdown {
  /*min-width: 150px;*/
  margin-top: 0.2rem;
}
.dropdownLabel {
  margin-left: 0.6rem;
}
.dropdownSurround {
  margin-right: 1rem;
  margin-bottom: 0.5rem;
}

.filterSurround {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  /*max-width: calc(100vw - 800px);*/
}

.headerLabel {
  text-align: right;
  padding-right: 0.5rem;
  width: 100%;
}
.headerSurround {
  padding-left: 2.8rem;
  padding-right: 10px; /* For consistency with table headers */
}
.headerValue {
  font-size: 0.9rem;
  margin-top: 0.4rem;
  margin-bottom: 2rem;
  margin-right: 0.5rem;
}

.resetButton {
  background-color: var(--light);
  border-radius: 4px;
  padding: 0.6rem;
  height: 40px;
  margin-top: 0.2rem;
  align-items: center;
  display: flex;
  color: var(--dark-two)
}
.resetButton:hover {
  cursor: pointer;
}

.dateButton {
  background-color: var(--light);
  border-radius: 4px;
  margin-left: -0.5rem !important;
  margin-right: 1rem;
  padding: 0.6rem;
  height: 18px;
  margin-top: 0.2rem;
  align-items: center;
  display: flex;
  color: var(--dark-two)
}
.dateButton:hover {
  cursor: pointer;
}
.dateButton.apply {
  margin-top: 1rem;
}
.dateButton.apply.active {
  background-color: blue;
}

.surround {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.blue {
  color: var(--warm-blue);
}

.green {
  color: var(--confirm);
}

.red {
  color: var(--alert);
}
