.input {
  --input-font: var(--small-text);
  --input-color: var(--text-light-color);
  --input-bg: var(--dark-two);
  --input-border-color: var(--dark-two);

  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid var(--input-border-color);
  background-color: var(--input-bg);

  font: var(--input-font);
  color: var(--input-color);
  transition: all 200ms ease-out;

  &.inverse {
    --input-bg: var(--secondary-bg);
  }

  &.search {
    background-image: url('../../../static/images/search.svg');
    background-repeat: no-repeat;
    background-position: 8px center;
    padding-left: 30px;
  }

  &.error {
    --input-border-color: var(--alert);
  }

  &.warning {
    --input-border-color: var(--earth);
  }

  &.disabled {
    opacity: 0.4;
  }

}

:global(.input--inverse) {
  --input-bg: var(--secondary-bg);
}


:global(.input__error-text) {
  margin-top: 5px;
  color: var(--text-alert-color);
}

:global(.input__warning-text) {
  margin-top: 5px;
  color: var(--earth);
}

/* Google Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { cursor: pointer; }

/* IE */
input[type=text]::-ms-clear {  cursor: pointer; width : 0; height: 0; }
input[type=text]::-ms-reveal {  cursor: pointer; width : 0; height: 0; }