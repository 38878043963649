.dropzone {
  --dropzone-border: dashed 1px var(--cloudy-blue);

  width: 100%;
  height: 220px;
  padding: 22px;
  display: flex;
  flex-direction: column;
  //justify-content: space-between;
  align-items: center;

  border-radius: 4px;
  border: var(--dropzone-border);
  text-align: center;
  cursor: pointer;
}

.exampleLink {
  margin-left: 16px;
  padding-left: 20px;
  background: url('../../static/images/excel.svg') left center no-repeat;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.contentWrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--secondary-bg);
}

.label {
  padding-top: 0.5rem;
}

.loadingWrapper {
  width: 240px;
}

.loadingTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logoBg {
  padding: 0.5rem;
  border-radius: var(--btn-border-radius);
  margin-top: 0.5rem;
}

.fullProcess {
  margin-top: 7px;
  position: relative;
  width: 100%;
  height: 2px;
  background: var(--cloudy-blue);
}

.process {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: var(--primary-color);
  transition: width 0.5s ease;
}

.logo {
  height: 80px;
  width: 120px;
  object-fit: contain;
}
