th {
    text-align: left;
    padding-left: 0.6rem;
}

.button {
    margin: 0.2rem;
}
.button.narrow {
    margin: 0rem;
    padding: 0.2rem 0.5rem;
}

.checkbox {
    -webkit-appearance: listbox;
    -moz-appearance: listbox;
    appearance: auto;
    margin-left: 0.5rem;
}

.checkboxSurround {
    display: flex;
    align-items: center;
    margin: 0.5rem

}

.closeButton {
    height: 0.7rem;
    width: 0.7rem;
    position: relative;
}
.closeButtonSurround:hover {
    cursor: pointer;
}

/*.priceRowSurround td{*/
/*    padding: 0.2rem 0.2rem 0rem 0rem;*/
/*}*/

.priceRowSurround input{
    margin: 0rem;
}