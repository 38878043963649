.container{
  display:flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: center;
  align-items: flex-start;
  max-width:300px;
  min-width: 300px;
  margin-right: 20px;
  transition: 0.5s;
  cursor: pointer;
}

.expandable{
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s ease;
  width:100%;
}

.expandable.active{
  max-height: 320px;
}


.percentilesContainer{
display: flex;

  flex-direction: row;
  justify-content: space-between;
}


.innerContainer{
  display:flex;
  flex-direction: row;
  align-items: center;
  height:100px;
  margin-top:-8px;
}


.title{
  font-weight: bold;
  font-size:23px;

}

.textWrapper{
  flex-direction: column;
  justify-content: center;
  margin-left:25px
}

.bar{
  transition:width 0.2s ease-out;
  height:4px;
  width:10px;
  margin-top:4px;
  border-radius: 10px;
}
