.ChartContainer {
  /*background-color: #1c202e;*/
  font-size: 13px;
  font-family: Arial, Helvetica, sans-serif;
  width: 100%;
  height: 400px;
  padding: 10px;
}
.volatilityTagContainer {
  width: 100%;
  display: flex;
  box-sizing: border-box;
}

.volatilityTagSlot {
  width: 20%;
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
.volatilityTag {
  width: 90px;
  margin: 0 auto;
  background-color: #6397ff;
  padding: 4px;
  color: #1c202e;
  text-align: center;
}

.TagsArrowsContainer {
  width: 100%;
  height: 60px;
  position: relative;
  margin-bottom: -50px;
}

.xAxisLineAll {
  width: 100%;
  height: 2px;
  /*background-image: linear-gradient(*/
  /*  to right,*/
  /*  #6397ff 0%,*/
  /*  #0077b2 21%,*/
  /*  #fb9900 39%,*/
  /*  #f27800 48%,*/
  /*  #e13800 67%,*/
  /*  #a50009 84%,*/
  /*  #a50009 100%,*/
  /*  #c8c8c8 100%*/
  /*);*/

  background-image: linear-gradient(
    to right,
    #a50009,
    #e13800,
    #f27800,
    #fb9900,
    /*#0077b2,*/
    #6397ff,
    /*#6397ff,*/
    /*#0077b2,*/
    #fb9900,
    #f27800,
    #e13800,
    #a50009
  );
}



.xAxisLinePositiveOnly {
  width: 100%;
  height: 2px;
  background-image: linear-gradient(
    to right,
    #6397ff,
    /*#0077b2,*/
    #fb9900,
    #f27800,
    #e13800,
    #a50009
  );
}
.xAxisMarkings {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.xAxisMark {
  width: auto;
  height: 54px;
  margin-bottom: -7px;
  display: flex;
}
.xAxisMark span {
  align-self: flex-end;
  text-align: center;
}
.midMark {
  margin-bottom: -8px;
  text-align: center;
}

.BenchmarkLabel {
  position: relative;
  margin-left: -15px;
  border: 1px solid purple;
}

.barArrowsContainer {
  height: 60px;
  width: 100%;
}
.BarsCanvas {
  display: flex;
  position: relative;
}
.BarColumn {
  margin: 0;
  padding: 0 5px;
  width: 10%;
  box-sizing: border-box;
}
.BarItem {
  background-color: #6397ff;
  margin-bottom: 2px;
  width: 100%;
  min-height: 20px;
  text-align: center;
  padding: 4px 0;
  color: #1c202e;
}

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip */
.tooltip .tooltiptext {
  visibility: hidden;
  min-width: 300px;
  width: 300px;
  background-color: black;
  color: #747b96;
  text-align: left;
  padding: 10px;
  border-radius: 6px;
  top: 32%;
  left: -60px;
  position: absolute;
  z-index: 1;
  cursor: default;
}
.tooltip .tooltipLast {
  left: -150px;
}
.tooltip:hover .tooltiptext {
  visibility: visible;
}
.tooltipBody .separatorLine {
  margin: 10px 0;
  border: 1px solid #747b96;
}
.tooltipBody .separatorLineFinal {
  margin: 10px 0;
  border: 1px dashed #747b96;
}
.tooltipCaption {
  color: #fff;
  font-weight: bold;
}
.tooltipCaptionValue {
  color: #fff;
  font-weight: bold;
}
.tooltipBody .listItem {
  display: flex;
  padding: 5px 0;
  box-sizing: border-box;
}
.tooltipBody .listItem .leftCol {
  text-align: left;
  width: 70%;
}
.tooltipBody .listItem .rightCol {
  text-align: right;
  width: 29%;
}
