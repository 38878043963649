.custodianAddSurround {
  display: flex;
  justify-content: space-between;
  flex: 1;
  padding-right: 0.5rem;
  justify-content: flex-start;
}


.custodianSurround {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 400px;
  margin: 0.2rem 0;
  margin-top: 15px;
}

.dropDownSurround{
  box-shadow: -3px 10px 40px -2px rgba(0,0,0,0.91);
  -webkit-box-shadow: -3px 10px 40px -2px rgba(0,0,0,0.91);
  -moz-box-shadow: -3px 10px 40px -2px rgba(0,0,0,0.91);
  color: var(--text-warning-color);
}

.custodiansSurround {
  margin: 0.5rem 0;

}

.toggleContainer{
  flex:2.5;
  justify-content:center;
  display: flex
}

.dropArea {
  min-width: 300px;
  margin-right: 1rem;
  flex: 1;
}

.dropAreaSurround {
  display: flex;
  width: 100%;
  min-height: 180px;
  /*background-color: blue;*/
}



.closeIcon {
  background: url("../../../../../../static/images/close.svg") center center no-repeat;
  width: 10px;
  height: 10px;
  transition: all 1s ease;
}

.error {
  font-weight: bold;
  color: yellow;
}

.error :global(.rs-picker-toggle .rs-picker-toggle-placeholder) {
  color: yellow;
}

.buttonArea {
  display: flex;
  /*align-items: center;*/
  justify-content: space-between;
  padding-right: 60px;
  height: 3rem;
  margin-top: 1.5rem;
}

.errorString{
  flex:1;
  align-self:center;
  text-align:right;
  margin-right:10px;
  color: yellow;
}

.settingsArea {
  /*background-color: var(--bg-dark);*/
  background-image: var(--gradient-dark);
  display: inline-block;
  padding: 0.5rem;
  border-radius: var(--btn-border-radius);
  margin-bottom: 2rem;

}

.settingsRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  margin-bottom: 0.5rem;
}

.settingsRow:last-child {
  margin-bottom: 0;
}

.settingsSurround {
  display: flex;
  justify-content: space-between;
}

.toggleSurround {
  margin-left: 1rem;
}
