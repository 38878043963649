.accountName {
  /*font-size: 1rem;*/
  text-align: center;
}

.accountSurround {
  width: 10rem;
  height: 10rem;
  background-color: var(--secondary-bg);
  border-radius: var(--btn-border-radius);
  margin: 1rem 1rem 0 0;
  padding: 0.5rem;
  transition: box-shadow 1s;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;

}
.accountSurround:hover {
  cursor: pointer;
  box-shadow: 0 0 10px var(--primary-color);
}

.accountsSurround {
  display: flex;
}

.companyLogo {
  height: 8rem;
  width: 8rem;
  object-fit: contain;
}
