.surround {
  position: absolute;
  width: 100%;
  z-index: 1;
  padding-left: inherit;
  left: 0;
  top: 0;


}

.content {
  //padding: 0.5rem 240px 0 1rem;
  display: flex;
  height: 34px;
  justify-content: space-between;
  flex-shrink: 0;
  flex-basis: auto;
  @media (max-width: 768px) {
    //margin-bottom: 50px;
  };
}

.portfolioCurrency {
  display: inline-block;
  margin-left: 1rem;
  font-size: 1rem;
  color: var(--text-color);
}

.spacer {
  height: 200px;
  background-color: red;
  width: 1000px;
}
.toolsWrapper {
  display: flex;
}
