.emptyPortfolioContainer{
  flex:1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  align-content: center;
  margin-top: 150px;
  p{
    text-align: center;
    margin-top: 40px;
    font-size: 18px;
    line-height: 22px;
  }
}

.blueLink{
  color:var(--primary-color)!important;
  transition:200ms all;
  &:hover{
    color:var(--light-blue)!important;
  }

}
