.container{

  background-color: var(--light-dark);
  display: flex;
  flex-direction: row;
  flex:1;
  padding:40px;

  padding-bottom: 0;
  margin-top:20px;
  border-radius: 8px;

}

.row{
  display:flex;
  flex-direction: row;
  margin-bottom:10px;
  justify-content: flex-start;
  align-content: center;
}

.column{
  flex:1;
  display: flex;
  flex-direction: column;
}

.textContainer{
  display:flex;
  flex:2;
  flex-direction: row;
  justify-content: space-between;
  max-width: 350px;
}

.textBold{
  font-weight: bold;
  margin-bottom: 30px;
  margin-right:5px;
}

.textWithTooltip{
  display:flex;
  flex-direction: row;
  align-content: center;
  width:130px;
  svg{
    margin-top:-2px
  }
}

.datePickerContainer{
  background-color: var(--dark-four);
  margin-bottom:25px;
  margin-left: -5px;
  margin-top:-5px;
}

.textBold:nth-child(4){
  margin-bottom: 0px;
}

.text{
  margin-bottom: 30px;

}

.text:nth-child(4){
  margin-bottom: 0px;
}

.textColumn{
  display:flex;
  flex-direction: column;
}

.uploadContainer{
  flex:1;
  display:flex;
  flex-direction: row;
}

.endContainer{
  flex:1;
  display:flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  button{
  float:right
  }
}

.addCustodianBtnContainer{
  display: flex;
  justify-content: flex-end;
  align-content: flex-start;
  align-items: flex-start;
  height:20px
}


.rowContainer{
  display: inline;
}

.downloadBig{
  display: flex;
  flex-direction: column;
  justify-content:center;
  align-items:center;
  padding:10px;
  height: 130px;
  background:var(--dark-four);
  cursor:pointer;
  margin-left:20px;

  border-radius: 4px;
  font-size:14px;
  transition: 0.1s linear;
  span{
    margin-top:8px
  }
  &:hover{
    opacity:0.9
  }
}

.selectContainer{
  background-color: var(--dark-four);
  margin-top: -10px;
  width: 200px;
  padding-left: 10px;
  margin-left: -5px;
  padding-bottom: 0px;
height: 30px;
  input{
    border:none;
    height:25px
  }
}

.labelBold{
  font-weight: bold;
  margin-right:10px;
}
