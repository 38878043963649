.dropBtn {
  background: url('../../static/images/cebab.svg') center center no-repeat;
  background-size: 24px;
  margin-top:5px;
  width: 16px;
  padding:8px 8px;
  height: 24px;
  cursor: pointer;
  margin-left: 3px;
  margin-right: 3px;
}

.customBtn {
  padding:0;
  width: 26px;
  height: 26px;
  cursor: pointer;
  margin-right: -10px;

}
