.assetTabs {
  margin-bottom: 20px;
}

.resultsContent {
  display: flex;
  flex-grow: 1;
  padding-top: 16px;
}

footer {
  display: flex;
  justify-content: flex-end;
}

.footerBtn {
  margin-left: 8px;
  min-width: 80px;
}


.scenarioBlocks {
  display: flex;
  width: 100%;
}

.scenarioBlock {
  width: 400px;
  flex-shrink: 0;
}

.scenarioBlockHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 36px;
}

.scenarioBlockContent {
  width: 100%;
  padding-top: 22px;
  height: calc(100% - 36px);
}

.scenarioBlockChart {
  flex-grow: 1;
  height: 400px;
  margin-left: 30px;
}

.chartTabs {
  min-height: 400px;
}
