.container{
  background-color: #2E364D;
  padding:20px;
  transition: all 0.2s ease-in-out;
}

.periodRow{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  align-items: center;
}

.marginTop{
  margin-top:20px;
  padding:15px;
}

.selectContainer{
  background-color: #23283D;
  padding:2px;
  padding-left: 8px;
  border-radius: 3px;
}

.datePicker{
  margin-top:10px;
  min-width:100px;
  :global(.k-picker-wrap), :global(.k-dateinput-wrap){
    background-color: #23283D;
    border-radius: 3px;
  }
}

.periodLabel {
  font-weight: 600;
  background: url('../../../../static/images/period.svg') left center no-repeat;
  background-size: 24px;
  padding: 0 8px 0 28px;
  font-size: 14px;
}

.periodImage {
margin:auto;
  width:80px
}

.noClose{
  display:none;
}


.periodDetail{
  margin-top:5px
}

.label{
  width:25px;
  font-size:14px;
  margin-top:10px;
  margin-right:10px
}
