.marketBlockTitle {
  margin-bottom: 24px;
}
.marketForm {
  margin-bottom: 24px;
}

.marketChart {
  margin-top: 16px;
}

.marketTable {
  margin-bottom: 40px;
  min-height: 300px;
}

.historyTitle{
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}
