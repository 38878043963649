.percentItem{
  background-color:#37383B ;
  border-radius: 2px;
  height: 4px;
  margin-bottom:6.5px;
  width: 60px;
  transition:background-color 0.3s;
}

.percentBar{
  display: flex;
  flex-direction: column;
  margin-top: 0.8rem;
}

.percentileHeader{
  font-weight: bold;
}
.percentBarContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.percentileText{
  margin:0.5rem
}
