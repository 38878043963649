:root {
  --header-size: 2rem;
}

.surround, .subHeaderSurround {
  position: absolute;
  width: 100%;
  z-index: 1;
  padding-left: inherit;
  left: 0;
  top: 0;
}

.subHeaderSurround {
  left: -30px;
  width: calc(100% + 60px);
  background: var(--light-dark);
  padding: 1rem;
  z-index: 0;
  border-left: 2px solid var(--bg-darkest);
}

.content {
  padding: 0.5rem 240px 0 1rem;
  position: fixed;
  background-image: linear-gradient(var(--bg-dark), var(--bg-darkest));
  width: 100%;
  /*width: 100%;*/
}

.contentInner {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-right: 1rem;
}

.header {
  /*margin-top: 5px;*/
}
.subHeader {
  font-size: 1rem;
}

.headerSurround {
  width: 500px;
}

.itemsSurround {
  display: flex;
  justify-content: space-between;
  /*width: 100%;*/
  /*overflow-x: auto;*/
  /*flex-shrink: 0;*/
  flex-wrap: wrap;
}

.portfolioCurrency {
  display: inline-block;
  margin-left: 0.5rem;
  font-size: 1rem;
  color: var(--text-color);
}

.systemTime {
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
  margin-bottom: 0px;
}

p {
  display: flex;
  justify-content: flex-end;;
}

.spacer {
  height: var(--header-size);
}

.subMenu {
  display: flex;
  margin: -22px -4px 24px;
}

.subMenuItem {
  background: var(--secondary-bg);
  font: var(--small-text);
  text-decoration: none;
  padding: 8px 16px;
  margin: 0 4px;
  border-radius: 4px;
}



/* ! stats */

.statSurround {
  display: flex;
  align-items: center;
}

.statLabel{
  font-size: 1rem;
  margin-left: 2rem;
}

.statValue{
  font-size: 1rem;
  font-weight: bold;
  margin-left: 1rem;
}
.statValue.plus {
  color: var(--confirm);
}
.statValue.minus {
  color: var(--alert);
}
