
.circle {
    background-color: var(--shaded);
    height: 1.2rem;
    width: 1.2rem;
    line-height: 1.2rem;
    text-align: center;
    border-radius: 0.6rem;
    display: inline-block;
    margin-right: 1rem;
    font-size: 0.8rem;
}
.highlighted > .circle {
    background-color: var(--warm-blue);
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    font-weight: bold;
}

.item {
    margin-right: 2em;
    color: var(--inactive);
    font-size: 0.8rem;
}
.highlighted > .item {
    font-weight: bold;
    color: var(--light);
    font-size: 1rem;
}

.itemSurround {
    display: flex;
    align-items: center;
    justify-content: center;
}

.line {
    width: 5rem;
    height: 1px;
    background-color: var(--shaded);
    display: inline-block;
    margin-right: 1rem;
    justify-self: end;
}
.highlighted > .line {
    background-color: var(--light);
}

.navButton {
    margin-left: 1rem;
    min-width: 5rem;
    background-color: var(--warm-blue);
}
.navButton:not(.disabled):hover {
    background-color: var(--light);
    color: var(--warm-blue);
    cursor: pointer;
}
.navButton.disabled {
    background-color: var(--darkgray);
    color: var(--dark-blue)
}

.surround {
    display: flex;
    margin-bottom: 2rem;
    justify-content: space-between;
}
