.advancedSettingsSurround {
  display: flex;
  margin-top: 2rem;
}
.advancedSettingsSection {
  flex: 1;
}
.advancedSettingsSection:not(:last-child) {
  padding-right: 2rem;
}

.inputNumber{
  width:120px;
  background:transparent;
  border: 1px solid #232425!important;
}

.inputNumber input{
  background-color: transparent;
}

.checkbox {
  appearance: auto;
  -webkit-appearance: none;

  border-color: var(--selected);
  border-width: 1px;
  border-style: solid;
  border-radius:3px;
  cursor: pointer;
  margin-left: 30px;
  width:12px;
  height:12px;
  min-width:12px;
}

.checkbox:checked{
  background: var(--selected);
}

.settingsArea {
  margin-top:1.2rem;
  margin-bottom:0.3rem;
  display: inline-block;
  min-width: 350px;
  width: 300px;
  /*height: 200px;*/
  transition: all 0.5s ease;
}

.settingsRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;

}

.settingsRow:last-child{
  margin-bottom: 0;
}


.reportButtonSurround {
  position: relative;
  /* z-index: 100; */
  padding: 12px;
  min-width: 130px;
  margin-right: 16px;
  margin-top: 16px;
  border: 1px solid var(--blue-grey);
  border-radius: 4px;
  box-shadow: 0 2px 16px 0 rgb(0 0 0 / 26%);
  background:transparent;
  text-align: center;
  font-weight: 600;
}

.generate{
  width:150px;
  text-align: center;
  background: var(--primary-color);
  border: 1px solid var(--selected);

}

.activeButton{
  background: var(--selected);
  border: 1px solid var(--selected);
}

.reportButtonSurround:hover {
  cursor: pointer;
  box-shadow: 0px 0px 10px var(--primary-color);
}

.reportButtonsSurround {
  display: flex;
}


.wide {
  width: 100%;
}
