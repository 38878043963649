.axisLabel {
  font-size:14px;
  marginBottom: 15px;
  fontWeight: 'bold';
}

.xTick {
  position: absolute;
  fontSize: 7px;
  textAlign: center;
  width: 30px;
  color: var(--text-color);
}

.popoverRow{
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--text-dark);
  font-size:11px
}

.marker {
  display:inline-block;
  margin-right:5px;
  margin-left:4px;
  border-radius:6px;
  width:6px;
  height:6px;
  background-color: #6677cc
}

.marker-header{
  height: 9px;
  width:9px;
  border-radius:9px;
}

.yTick {
  position: absolute;
  left:-40px;
  width: 70px;
  textAlign: right;
  fontSize: 7px;
  color: var(--text-color);
}

.scatterPoint{
  cursor:pointer;
}

