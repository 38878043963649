.formBtn {
  //line-height: 32px;
  padding: 0.2rem 0.5rem;
  border: 0;
  &.smallLineHeight {
    line-height: 16px;
    font-size: 0.6rem;
  }
}

.toggleSurround {
  display: inline-flex;
  border-radius: 4px;
  background: rgba(59, 71, 112, 0.5);

  .toggleBtn {
    flex-grow: 1;
    min-width: auto;
  }
}


.toggleBtn {
  --btn-bg-color: transparent;
  background-color: var(--btn-bg-color);
  border: 0;

  &.active {
    --btn-bg-color: var(--dusk);
  }

}
