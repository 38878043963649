.sliderBlock {
  --title-color: var(--text-color);
}
.sliderBlockContent {
  display: flex;
  margin-top: 5px;
}

.sliderBlockSlider {
  flex-grow: 1;
  display: flex;
  align-items: center;
}
.sliderDisabled {
  background-color: transparent;
  //background-color: #1c202e;

}

.sliderBlockSliderValue {
  min-width: 20px;
  margin-left: 15px;
  text-align: right;
}
.sliderHandlerAreaWrapper {
  position: absolute;
  width: 100%;
  height: 2px;
  overflow: hidden;
}

.sliderHandlerArea {
  position: relative;
  width: calc(var(--rc-handler-size) + var(--rc-handler-border-width) * 2);
  height: 100%;
  margin-left: -7px;

&::before {
   content: '';
   width: 20px;
   height: 100%;
   position: absolute;
   left: -20px;
   background: linear-gradient(to right, transparent, var(--rc-handler-color));
 }

&::after {
   content: '';
   position: absolute;
   width: 20px;
   height: 100%;
   right: -20px;
   background: linear-gradient(to left, transparent, var(--rc-handler-color));
 }
}


/*-- Basic Sliders Layout -- */
.scenariosItems{
  display: flex;
  margin-bottom: 25px;
}

.scenariosItemsColumn{
  flex-direction: column;
}

.scenariosItem{
  flex-grow: 1;
  padding: 0 12px;
  margin-bottom: 12px;
}

/*-- Historic Sliders Layout --*/
.eventItemsList {
  margin-top: 30px;
}

.eventItem {
  --btn-bg-color: #000;
  margin: 0 4px 4px 0;
  opacity: 0.3;
}
