.assetFilter {
  position: relative;
  width: 100%;
  height: 33px;
  border-radius: 4px;
}

.input {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 8px 16px 8px 8px;
  border: 1px solid var(--dark-two);
  background-color: var(--secondary-bg);
  color: var(--text-light-color);
  font: var(--medium-text);
  border-radius: inherit;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  &_error {
    border-color: var(--alert);
  }

  &:hover .resetIcon {
    visibility: visible;
  }
}

.resetIcon {
  visibility: hidden;
  position: absolute;
  right: 6px;
  cursor: pointer;
  &:before {
    content: '×';
    font-size: 16px;
    font-style: normal;
  }
}
