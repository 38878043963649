.confirmWrapper {
  --overlay-bg: rgba(28, 32, 46, 0.7);

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--overlay-bg);
  z-index: 9999999
}

.confirmWindow {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 330px;
  min-height: 207px;
  padding: 40px;
  border-radius: 4px;
  box-shadow: 0 10px 30px 0 var(--black-10);
  background-color: var(--dark);

  text-align: center;
}

.confirmWindowTitle {
  color: var(--light);
  font: var(--medium-text);
}

.confirmWindowText {
  flex-grow: 1;
  padding-top: 5px;
}

.confirmWindowFooter {
  margin: 0 -5px;
  display: flex;

  > * {
    flex-grow: 1;
    margin: 0 5px;
    font: var(--medium-bold-text);
    line-height: 1.25;
  }
}

.childrenContainer {
  margin-top: 20px;
  margin-bottom: 20px;
  min-height: 50px
}

.cancelBtn {
  --btn-text-color: var(--cloudy-blue);
}

.confirmBtn {
  --btn-bg-color: var(--text-alert-color);
  --btn-text-color: var(--text-white);
  &.infoBtn {
    --btn-bg-color: var(--primary-color);
  }
}

.textContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 10px 20px 10px
}