.delTradeBtn {
  display: none;
  background: url('../../../../../../static/images/close.svg') center center no-repeat;
  width: 18px;
  height: 18px;
  transition: all 1s ease;
}

.chevronImageSurround {
  display: flex;
}

.assetLabel{
  background-color: var(--cloudy-blue);
  border-radius:4px;
  padding:2px;
  color: var(--selected)
}

.tableRow:hover {
  .delTradeBtn {
    display: block;
  }
}

.tableColumn:not(:nth-child(-n+5)) {
    * {
      text-align: right;
      width: 100%;
    }
}

.headerContainer:not(:nth-child(-n+5)) {
  div {
    text-align: right;
    width: 100%;
  }
}

.blue {
  color: var(--light-blue);
}

.green {
  color: var(--confirm);
}

.red {
  color: var(--alert);
}

.rightAligned {
  text-align: right;
  padding-right: 10px;
  width: 100%;
}

.lastItem {
  padding-right: 0;
}

.reportButton {

  opacity: 0.8 !important;
  &:hover {
    opacity: 0.5 !important
  }
}

.fade {
  opacity: 0.4;
}
