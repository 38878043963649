.instrumentForm {
  display: grid;
  grid-gap: 16px 8px;
  grid-template-columns: 3fr 1.5fr 2fr 2fr;
  grid-auto-rows: min-content;
  align-items: baseline;
}

.formFooter {
  grid-column: 1/-1;
  justify-self: end;
}

.noteField {
  grid-column: span 2;
  grid-column-start: 3;
  grid-column-end: 5;
}
