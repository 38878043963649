/*:root {*/
/*  --header-size: 2rem;*/
/*}*/

.subHeaderSurround {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: absolute;
  width: calc(100% + 60px);
  z-index: 0;
  padding: 1rem 26px;
  /*padding-left: 18px;*/
  left: -30px;
  top: 0;
  background: var(--light-dark);
  border-left: 2px solid var(--bg-darkest);
}

.spacer {
  height: 10rem;
}



.statSurround {
  --duration: 0.4s;
  position: relative;
  padding: 1rem 1rem 0.5rem 1rem;
  margin: 0 0.5rem 0.5rem 0.5rem;
  background-image: var(--gradient-dark);
  border-radius: var(--btn-border-radius);
  box-shadow: 3px 3px 6px 0 var(--bg-darkest);
  min-width: 10rem;
  transition: box-shadow var(--duration) ease-in-out, background-image var(--duration), transform var(--duration) ease-in-out;
  cursor:pointer;
}

.statSurround .statBg {
  position: absolute;
  background-image: var(--gradient-jack-dorsey);
  /*z-index: 0;*/
  /*background-color: red;*/
  opacity: 0;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  border-radius: var(--btn-border-radius);
  transition: all 0.5s;
}

.statSurround:hover .statBg {
  opacity: 1;
}

.statSurround:hover{
  /*background-color: red;*/
  /*background-image: var(  --gradient-jack-dorsey);*/
  transform: scale(1.1);
}
.statSurround::after {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 11;
  height: 100%;
  width: 100%;
  content: " ";
  box-shadow: 3px 3px 10px 0 var(--bg-darkest);
  opacity: 0;
  transition: opacity var(--duration) ease-in-out;
}

/* Transition to showing the bigger shadow on hover */
.statSurround:hover::after {
  opacity: 1;
}

.statIcon {
  position: relative;
}

.statLabel {
  position: relative;
  transition: var(--duration) all;
  color: var(--text-disabled-color);
}

.statSurround:hover .statLabel{
  transition: var(--duration) all;
  color: var(--text-color);
}

.statValue {
  position: relative;
  font-size: 1.5rem;
  line-height: 2rem;
  min-height: 2rem;
}
