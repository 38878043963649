.searchInp {
  width: 140px;
  height: 100%;
  margin-right: 16px;
  font-size: 12px;
  background-color: var(--secondary-bg);
}

.tabs {
  margin-bottom: 28px;
}

.portfoliosList {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -4px;
}


.portfoliosListItem {
  width: 100%;
  max-width: 100%;
  flex-grow: 1;
  text-decoration: none;
}

.filters {
  margin-bottom: 28px;
  display: flex;
}

.filterItem {
  background-color: var(--primary-color);
  border-radius: 15px;
  padding: 0 7px 0 14px;
  display: flex;
  align-items: center;
  margin-right: 15px;


  & > span {
    margin-right: 5px;
    font-weight: bold;
  }

}

.filterOptions {
  display: flex;
  align-items: center;
}

.filters {
  margin-bottom: 18px;
  display: flex;
}

.filterItem {
  background-color: var(--primary-color);
  border-radius: 15px;
  padding: 0 7px 0 14px;
  display: flex;
  align-items: center;
  margin-right: 15px;


  & > span {
    margin-right: 5px;
    font-weight: bold;
  }

}

.filterOptions {
  display: flex;
  align-items: center;
}
