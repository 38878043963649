.infoBlock {
  display: flex;
  align-items: center;
  min-height: 56px;
  min-width: 170px;
  line-height: 20px;
}

.infoBlockImage {
  margin-right: 14px;
}

.infoBlockContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.infoBlockTitle {
  font-size: 17px;
  font-weight: 500;
  color: var(--text-color);
  margin-top: 5px;
  white-space: nowrap;
}

.infoBlockTitle.noCard{
  font-weight: 500;
  font-size:17px;
}

.infoBlockValue {
  font-size: 16px;
  font-weight: 500;
  color: var(--text-light-color);
  margin-bottom: 5px;
  padding: 2px;
  overflow: hidden;
  white-space: nowrap;

  &.plus {
    color: var(--confirm);
  }

  &.minus {
    color: var(--alert);
  }
}

.infoBlockValue.noCard{
  font-weight: 500;
  font-size: 17px;
}

.infoBlockDiff {
  display: inline-block;
  margin-left: 12px;
  border-radius: 2px;
  padding: 2px 6px;
  font: var(--small-text);
  background: var(--selected-bg);
  color: var(--cloudy-blue);
}
.tooltipNotesItem {
  font-weight: bold;
  color: red;
}
/* Important! Don't modify the below class. Needed for changing the behaviour of react-tooltip for use in Context Help */
.tooltipWrapper {
  max-width: 500px;
  pointer-events: auto;
  z-index: 10000000;
  background-color: blue;
  pointer-events: auto !important;
  &:hover {
      visibility: visible !important;
      opacity: 1 !important;
   }
}
.tooltipContainer h3, .tooltipContainer h4 {
  color: black;
}

.helpIcon {
  cursor: pointer;
  display: inline-block;
  vertical-align: super;
}
