.userCard {
  background-color: #292D33;
  padding:7px 5px 7px 5px;
  cursor: pointer;
  transition: 0.1s;
  &:hover{
    background-color:#3D3F43;
  }
}

.input{
  background:  var(--dark);
  border:none;
  height:32px;
  border-radius: 5px;
}

.resultsWrapper{
  position: absolute;
  min-width: 300px;
  z-index: 99;
  top:30px;
  background-color: var(--dark);
  max-height:400px;
  overflow-y: scroll;
  overflow-x: hidden;
  border-radius: 4px;
}
