.groupWorkHeader{
  display:flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 40px;
  @media (max-width: 1160px) {
    flex-direction: column;
    :nth-child(1) { order: 1; }
    :nth-child(3) { order: 2; margin-top:10px;margin-bottom: 10px;padding-bottom: 10px }
    :nth-child(2) { order: 3; }
  }
  @media (max-width:414px){
    min-height: 300px;
  }
}

.close{
  color:var(--warm-grey)!important;
  cursor: pointer;
  font-size:10px;
  font-weight: 300;
  font-family: Arial, sans-serif;
}

.filterItem {
  background-color: var(--selected);
  border-radius: 15px;
  padding: 5px;
  display: flex;
  align-items: center;
  margin-right: 15px;
  margin-bottom: 0;
  max-height: 50px;
  & > span {
    margin-right: 5px;
    margin-left:5px;
    font-weight: bold;
    font-size:10px;
    color:white;
  }

}

.tag-remove::before {
  content: 'x';
  color: #fff;
  font-weight: 300;
  font-family: Arial, sans-serif;
}

.workGroupListHeader{
  display:flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top:20px;
  margin-bottom:10px;
}

.listHeaderButtons{
  display:flex;
  flex:1;
  justify-content: flex-end;
  flex-direction: row;
  float:right;
  margin-top:10px;
  margin-bottom: 10px;
  @media (max-width:414px){
  height:100px;
    margin-right:5px;
  }
}

.listHeaderButton{
  cursor: pointer;
  margin-left: 50px;
  font-size: 14px;
  color: var(--earth-light);
  font-weight: bold;
}

.searchContainer{
  flex:1;
  display:flex;
  align-items: flex-end;
}

.notificationContainer{
  position: relative;
  flex:1;
}

.info {
  background-color: var(--dark-two);
  flex:1;
}

.warningItem{
 color: var(--earth-light);
  font-size: 12px;
  font-weight:bold;
  justify-content: center;
  cursor: pointer;
}

.notificationList{
  display: inline-block;
  margin-top:15px;

}
.warningSign{
  position: absolute;
  right:10px;
  width:25px;
  top:10px;
}

.infoItem{
  font-size:18px;
  margin-top: 15px;
  margin-bottom: 15px;
  width:100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.label{
  margin-left:15px;
  color:var(--text-color) !important;
  font-weight: bold;
}

.value{
  color:white;
  color:var(--text-color);
  font-weight: bold;
}

.cardsContainer{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
}

.labelContainer{
  align-items: center;
}

