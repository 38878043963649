
.clearButton {
    position: absolute;
    right: 0.3rem;
    height: 100%;
    display: flex;
    align-items: center;
    opacity: 25%;
    width: 1rem !important;
}
.clearButton:hover {
    opacity: 100%;
    cursor: pointer;
}