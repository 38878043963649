.container {
  min-width: 100%;
  border: 1px solid var(--dark-blue);
  display: grid;
  grid-template-columns: 340px 150px  1fr  1fr  1fr;
}

.shadow {
  box-shadow: 10px 0px 4px 0px rgba(0,0,0,0.1);
  position:relative;
  z-index: 1;
}

.headerTitleContainer {
  height: 80px;
}

.subHeaderTitleContainer {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  &>h3{
    display: flex;
    align-items: center;
  }
}

.column {
  transition: min-width 0.5s;
  transition-timing-function: ease-in-out;

  border-left: 1px solid var(--dark-blue);
  min-width: 100px;

  &:not(:last-child) {
    margin-right: 15px;
  }

  &:not(:last-child) {
    border-right: 1px solid var(--dark-blue);
  }
}

.column h4 {
  font-size: 12px;
}

.cell{
  display: flex;
  align-items: center;
  padding: 20px;
  min-height: 80px;

  &:not(:last-child) {
    border-bottom: 1px solid var(--dark-blue);
  }
}

.detailCell {
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  &>*{
    height: 100%;
    display: flex;
    align-items: center;
  }
}

.extraHeight {
  height: 170px
}

.titleContainer {
  grid-template-columns: 2.7fr 1fr;
  display: grid;
  width: 100%;
  height: 50%;

  &>*:first-child {
    padding-right: 5px;
  }
}

.titleContainer h4 * {
  font-weight: bold;
  color: var(--cloudy-blue);
}

.titleContainer1 {
  grid-template-columns: 2fr 1fr 1fr;
  display: grid;
  width: 100%;
  height: 50%;

  &>*:first-child {
    padding-right: 5px;
  }
}

.details {
  display: grid;
  grid-template-columns: 1fr 1fr 0.7fr 1fr;
  width: 100%;
  margin-top: 10px;
  align-items: start;
  height: 50%;

  &>*:not(:last-child) {
    border-right: 1px solid var(--dark-blue);
    margin-right: 10px;
  }
}

.detailsSecondRoiw {
  grid-template-columns: 2fr 1.7fr;
}

.details h5 {
  color: var(--blue-grey)
}

.periodHeader {
  background: var(--dark-three);
  justify-content: center;
  cursor: pointer;
  position:relative;

  &>* {
    position: absolute;
    right: 10px;
  }

  &:hover {
    background: var(--dark);
  }
}

.transparent {
  filter: blur(0.5px) ;
  background: transparent !important;
  /* opacity: 0.5 */
  border: solid 1px var(--dark-blue)
}

.totalHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px;
  height: 100px;

  &:not(:nth-child(2)) {
    margin-left: 15px;
  }

  &>* {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &>*:last-child {
    border-top: 1px solid var(--dark-blue);
  }
}

.totalContainer {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  height: 50%;


  &>* {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items:center;

    &:not(:last-child) {
      border-right: 1px solid var(--dark-blue);
    }
  }
}

.totalContainerExpanded {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0px;


  &>* {
    margin-bottom: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items:center;
    align-items: flex-end;
  }
}

.iconsCell {
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px 4px 4px 4px;
  width: 25px;
  height: 20px;
  font-size: 11px;
  cursor: pointer;
}

.neutral {
  background: gray
}
.negative {
  background: var(--alert)
}
.positive {
  background: var(--confirm)
}

.onlyIcons {
  align-items: flex-start
}

.expanded .onlyIcons {
  display: none;
}

.expanded {
 min-width: 420px;
}

.expandHeader {
  display: none;
}

.expanded .totalContainer, .expanded .totalContainerExpanded {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

  &>* {
    align-items: center;

    &:not(:last-child) {
      border-right: 1px solid var(--dark-blue);
    }
  }

}

.expanded .totalContainerExpanded * {
  margin-bottom: 0px;
}


.expanded .expandHeader {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  text-wrap: none;
}

.expanded .filters {
  display: none
}

.confirm {
  color: var(--confirm);
}

.error {
  color: var(--alert);
}

.blue * {
  color: var(--warm-blue);
}

.custodianFilter {
  width: 130px;
}

.settingsButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.totalValue {
  margin-left: 5px;
}

.dropdown {
  width: auto;
}

.dropdowmLabel {
  width: auto;
  margin-left: 5px
}

.imputContainer {
  width: auto;
}
