.dashboardGrid {
  grid-template-rows: min-content min-content 300px auto;
}

.assetsDashboardGrid {
  grid-template-rows: min-content 300px minmax(355px, auto);
  grid-auto-rows: minmax(300px, auto);
}

.dashboardMenu{
  margin-top: 20px;
  margin-bottom: 10px;
}

.dashboardAssetsMenu{
  margin-bottom: 10px;
}

.dashboardCustodiansFilter {
  position: relative;
  width: 100%;
  height: 1px;
}

.tableColumn:nth-last-child(-n+3) {
  justify-content: flex-end;
  padding-right: 10px;
}

.headerContainer:nth-last-child(-n+3) {

  div {
    justify-content: flex-end;
    text-align: right;
    width: 100%;
  }
}


.dashboardInfo {
  display: flex;
  max-width: 1200px;
  flex-shrink: 0;
  margin:28px 0;
  flex-wrap: wrap;
}

.dashboardInfoItem {
  --border-color: rgba(102, 119, 204, 0.2);

  display: flex;
  padding-right: 12px;
  margin-right: 0px;

  &:last-child {
    border: none;
    padding-right: 0;
    margin-right: 0;
  }

  &.dashboardInfoItemStart {
    justify-content: flex-start;
  }
}

.defaultContainer {
  display: flex;
  min-height: 500px;
  margin-top: 20px;
}

.contaienr {
  margin-top: 15px;
}

.container {
  margin-top: 75px;
}
.chartToolsContainer {
  display: flex;
  justify-content: flex-end;
  //flex-flow: row-reverse;
  //padding-right: 200px;
  //background: red;
}
.currencyFilter {
  display: block;
  color: var(--text-white);
  margin-right: 10px;
  margin-left: 4px;
}
.currencyFilterLabel {
  padding: 0 8px 0 30px;
  line-height: 24px;
}

.reportBlock {
  grid-template-rows: 355px minmax(300px, auto);
  height: 100%;
}

.reportInfo {
  display: grid;
  grid-gap: 24px;
  flex-grow: 1;
  align-content: center;
}

.infoItem {
  display: flex;

  &.dashboardInfoItemStart {
    justify-content: flex-start;
  }
}

.portfolioName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: normal;
  display: flex;
  align-items: center;
}

.noData {
  color: var(--darkgray)
}

.portfolioStructure {
  /*align-items: center;*/
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 5px;
  min-height: 57px;
}

.portfolioStructureHeader {
  font-size: 18px;
  font-weight: 500;
  color: var(--text-color);
  margin-bottom: 10px;
}

.portfolioStructureContent {
  font-size: 20px;
  /* color: #a9b2d1; */

  display: flex;
}

.assetItem {
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-right: 21px;
  }

  & img {
    margin-right: 12px;
  }
}

.pnlStatusContainer {
  margin-right: 15px;
}

.verticalContainer {
  display: flex;
  flex-direction: row;
}

.periodSelectorHalfWidth {
  width: 50%;
  border: 1px solid red;
}
