.dashboardTitleContainer {
  margin-top: 10px;
  margin-bottom: 10px;
}
.dashboardTitle {
  margin: 10px 0;
}

.dashboardTitleContainer {
  margin-top: 10px;
  margin-bottom: 10px;
}
.dashboardTitle {
  margin: 10px 0;
}

.dashboardInfo {
  display: flex;
  max-width: 1024px;
  max-height: 90px;
  flex-shrink: 0;
  margin:28px 0;
}

.dashboardInfoItem {
  --border-color: rgba(102, 119, 204, 0.2);

  display: flex;
  padding-right: 12px;
  margin-right: 6px;

&:last-child {
   border: none;
   padding-right: 0;
   margin-right: 0;
 }

&.dashboardInfoItemStart {
   justify-content: flex-start;
 }
}


.ChartsLayout {
  width: 100%;
  display: flex;
  margin: 0;
  padding: 10px;
  box-sizing: border-box;
}

.totalPL {
  display: flex;
  max-height: calc(100% - 35px);
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background: var(--selected-bg);
}

.totalValue {
  font-size: 18px;
  margin-top: 5px;
  color: var(--text-light-color);
  font-weight: 500;
}

.profitChart {
  flex-grow: 1;
  margin-right: 30px;
}

.totalImage {
  margin-bottom: 16px;
  width: 84px;
  height: 84px;
  background: url('../../../../static/images/dashboard/invest.svg') no-repeat;
  background-size: cover;
}

.SlidersBlockContainer {
  margin-top: 20px
}
.categorySelectorBlock {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}


/* Chart Legend */

.chartLegendContainer {
  display: flex;
  width: 90%;
  margin: 20px 120px 10px 30px;
  box-sizing: border-box;
}
.legendTitle {
  font-size: 16px;
  width: 80%;
  margin-bottom: 5px;
  box-sizing: border-box;
}
.legendIcon {
  width: 30px;
  margin: 0 10px;
  box-sizing: border-box;
  vertical-align: middle;
}
.legendItem {
  margin-left: auto;
  box-sizing: border-box;
  height: 30px;
  min-width: 100px;
}
.equityColor, .fxColor, .yieldColor, .volatilityColor {
  display: inline-block;
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  margin: 0 16px 0 0;
  background-color: var(--chart-1);
}
.fxColor {
  background-color: var(--chart-2);
}
.yieldColor {
  background-color: var(--chart-3);
}
.volatilityColor {
  background-color: var(--chart-4);
}
.textGreen {
  color: var(--text-success-color);
}
.textRed {
  color: var(--text-alert-color);
}
