.percentItem{
  background-color:#37383B ;
  border-radius: 2px;
  height: 15px;
  margin-right:10px;
  width: 3.5px;
  transition:background-color 0.3s;
}

.percentBar{
  display: flex;
  flex-direction: row;
  position: absolute;
  margin-top: 0.5rem;
}
