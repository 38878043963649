
.noScrollbar::-webkit-scrollbar {
  display: none;  /* Safari and Chrome */
}

.eventDate {
  position: absolute;
  align-self: center;
  justify-self: center;
  padding-left: 4px;
}


.eventDateSurround {
  display: flex;
  height: 80px;
  width: 40px;
}

.draggableContainer{
  z-index:98;
  box-shadow: 2px 2px 6px 3px var(--bg-darkest) !important;
  opacity: 0.5;
  padding-top:20px!important;
}

.draggableContainer:hover{
  opacity: 1;
  transition: opacity 220ms ease 0s;
}


.container{
  transition: 0.4s ease;
  width:480px;
  position: absolute;
  right: 0;
  background: transparent!important;
  box-shadow: none;
  z-index: 98;
  cursor: grab;
  padding-top:0!important;
}

@media (min-width:1800px) {
  .expandButton{
    display: none;
  }
  .container{
    padding:0!important;
    position:relative;
    top:0;
  }
  .draggableContainer{
    padding-top:20px!important;
    position: relative;
    opacity: 1;
    cursor: auto;
  }
}


.container :global(.rs-btn-default){
  background: var(--dark-blue);
  padding:10px;
  margin-left:1px;
  padding-bottom: 7px;
  padding-top: 7px;
}

.container:not(.collapsed) :global(.rs-btn-default){
  padding:8px;
  padding-bottom: 4px;
  padding-top: 4px;
}

.container.collapsed{
  position: fixed;
  top: 280px;
  width:60px;
  background: var(--light-dark)!important;
  padding-left:15px;
  padding-top:7.5px;
  padding-bottom: 7.5px;
  z-index: 0;
  cursor: default;
}

.pulse {
  animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(255, 255, 255, 0.2);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
  }
}


.titleRow{
  display:flex;
  flex-direction: row;
  margin-bottom: 5px;
  align-items: center;
  align-content: center;
}
.eventSurround {
  background:var(--light-dark);
  border-radius: var(--btn-border-radius);
  margin-top: 0.8rem;
  /*box-shadow: -5px 5px 5px 0 var(--secondary-bg);*/
  display: flex;
  align-items: center;
}

.eventDetails {
  margin-left: 1rem;
}

.eventPayout {
  font-size: 1.2rem;
  padding-top: 6px;
  padding-bottom: 6px;
  font-weight: 500;
}

.eventTitle {
  font-size: 1rem;
}

.item{
  margin-top: 0.5rem;
  /*margin-left: 1rem;*/
}

.trendSurround { width: 60px }

.semiCircle {
  height: 80px;
  width: 40px;
  border-radius: var(--btn-border-radius) 40px 40px var(--btn-border-radius);
  background-color: var(--dark-two);
}

.semiCircleSmall {
  height: 64px;
  width: 32px;
  background-color: var(--dark-two);
  border-radius: var(--btn-border-radius) 32px 32px var(--btn-border-radius);

}
.newCard {
  margin-top:20px;
  padding-bottom: 10px;
  cursor:pointer;
}

.subheading {
  font-size: 16px;
}


.collapsedIcon{
  justify-content: center;
  margin-top:10px;
  align-self: center;
  position: relative;
}

.indicatorWrapper{
  position: absolute;
  background: var(--primary-color);
  border-radius:5px;
  font-size:6px;
  height:10px;
  width:10px;
  right:0;
  top:0;
  font-weight: bold;
  font-family: "Avenir";
  text-align: center;
  vertical-align: center;
  justify-content:center;
}

.indicator{
  position: relative;
  bottom:1.2px
}

.topRow{
  display:flex;
flex-direction: row;justify-content: space-between;
  padding-bottom: 10px;
}


.expandButton{align-self: flex-end;
}

.expandButton.collapsed{
  position: absolute;
  right:40px;
}

.modalTitle {
  fontSize:32px;
}

.modalContainer{
  height:800px;

  z-index: 999999;
}

.modalContainer :global(.rs-modal-wrapper){
 z-index: 999999;

}


.modalContainer :global(.rs-modal-content){
  padding:40px;
}

.modalContainer :global(.rs-modal-content){
  background: var(--light-dark);
}
.modalBody{
  font-size:18px;
  line-height: 30px;
  font-weight: 400;
  white-space: pre-wrap;
}

.link {
  margin-top: 40px;
  font-size: 18px;
  color: var(--link)
}
