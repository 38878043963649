.helpIcon {
  cursor: pointer;
  display: inline;
  vertical-align: super;
  opacity: 1 !important;
}

.link:hover {
  cursor: pointer;
}

/* Important! Don't modify the below class. It overrides the behaviour of react-tooltip needed in particular for Context Help Icon */
.tooltipWrapper {
  max-width: 500px;
  pointer-events: auto;
  z-index: 10000000;
  background-color: blue;
  pointer-events: auto !important;
&:hover {
   visibility: visible !important;
   opacity: 1 !important;
 }
}
.tooltipContainer {
  text-align: left;
}
.tooltipContainer h3, .tooltipContainer h4 {
  color: black;
}
.tooltipHtml p, .tooltipHtml ul {
  margin-top: 12px;
  text-align: left!important;
}
p {
  text-align: left;
}
