.input {
  --input-font: var(--small-text);
  --input-color: var(--text-light-color);
  --input-bg: var(--dark-two);
  --input-border-color: var(--dark-two);
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid var(--input-border-color);
  background-color: var(--input-bg);
  font: var(--input-font);
  color: var(--input-color);
  transition: all 200ms ease-out;
}

.optionsScrollWrap {
  overflow: hidden;
  max-height: 8rem;
  transition: height 500ms ease-in-out;
  width: 100%;
}
.options {
  max-height: 8rem;
  overflow-y: scroll;
  background: var(--bg-dark);
  height: 100%;
  width: calc(100% + 20px);
}

.row {
  padding: 0.2rem 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.row:hover {
  cursor: pointer;
  background: var(--bg-color);
  color: white;
}


.selected {
  background: var(--confirm);
  color: var(--bg-dark);
}
