.DashboardMenu {
  list-style: none;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  z-index: 2;
}

.assetClassPopover {
  background-color: var(--text-color);
  color: var(--text-dark) !important;
  overflow: visible;
}
.assetClassPopover div.DashboardMenu a:hover {
  color: red;
}

.assetClassPopover .ulSurround .ul a {
  color: var(--text-dark);
}

.assetClassPopover :global(.rs-popover-arrow):after {
  border-bottom-color: var(--text-color) !important;
}

.ulSurround {
  padding: 0.5rem;
}
.ul {
  list-style-type: none;
  font-size: 0.9rem;

}

.caretDown {
  margin-left: 10px;
}

.DashboardMenuItem{
  position: relative;
  padding: 4px;
  margin-right: 25px;
  border-radius: 4px;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.26);
  height: 46px;
  display: flex;
  align-items: center;
  width: 150px;
}
/*.DashboardMenuItem:hover {*/
/*  background: var(--bg-color);*/
/*}*/
.DashboardMenuItem.selected {
  background: var(--selected);
  border: 1px solid var(--selected);
}

.icon {
  opacity: 1;
  margin-right: 10px;
  width: 20px;
  height: 20px;
}
/*.DashboardMenuItemSelected .AssetClassHeader .icon {*/
/*  opacity: 1;*/
/*  !*filter: invert(30%) sepia(78%) saturate(351%) hue-rotate(99deg) brightness(92%) contrast(97%);*!*/
/*}*/


.AssetClassHeader {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.assetClassTitle {
  font: var(--small-title);
  min-width: 60px;
}
