.scenarioBlocks {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.scenarioBlock {
  width: 400px;
  flex-shrink: 0;
}

.scenarioBlockHeader {
  display: flex;
  align-items: center;
  height: 36px;
}

.scenarioBlockContent {
  width: 100%;
  padding-top: 22px;
  height: 100%;
}

.configTypes {
  margin-left: 16px;
  border-radius: 4px;
  background: rgba(59, 71, 112, 0.5);
}

.configTypeBtn {
  background-color: transparent;
  border: 0;

  &.active {
    --btn-bg-color: #3c4871;
    background-color: var(--btn-bg-color);
  }
}

.scenarioBlockChart {
  flex-grow: 1;
  margin-left: 30px;
}
