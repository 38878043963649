@media print {
  :root {
    --darkgray: #70778f;
    --light: #000;
    --chart-1: #6677cc;
    --chart-2: #c5a400;
    --chart-3: #59abb3;
    --chart-4: #cc6f66;
    --chart-5: #56b877;
    --chart-6: #cc66aa;
    --chart-7: #85b861;
    --chart-8: #a266cc;
    --alert: #b9424a;
    --confirm: #2b8b68;
    --warm-blue: #4162d9;
    --dusk: #3c4871;
    --earth: #946c3d;
    --cocoa: #914d47;
    --dark: #fff;
    --dark-blue: #343a54;
    --blue-grey: #757fa1;
    --black-10: rgba(0, 0, 0, 0.1);
    --cloudy-blue: #000;
    --warm-grey: #9a9a9a;
    --dark-two: rgb(240, 240, 240);
    --dark-three: rgb(221, 221, 221);
  
    --bg-color: #fff;
    --secondary-bg: var(--dark);
    --selected-bg: #fff;
  
    --primary-color: var(--warm-blue);
  
    --border-color: var(--cloudy-blue);
  
    --tab-active-color: #000 !important;
    --text-color: var(--cloudy-blue);
    --text-dark: var(--dark);
    --text-light-color: var(--light);
    --text-alert-color: var(--alert);
    --text-success-color: var(--confirm);
    --text-white: #000;

    --tab-color: var(--text-color);
    --tab-active-color: #000;
    --tab-disabled-color: rgba(0,0,0,0.5);
    --content-text-color: var(--text-color);
    --content-background: transparent;
    --value-color: var(--text-white);


    --btn-bg-color: var(--primary-color);

    --btn-border-color: var(--btn-bg-color);
    --btn-outline-border-color: var(--cloudy-blue);
  
    --btn-border-radius: 4px;
  
    --btn-text-color: var(--text-white);
    --btn-outline-text-color: var(--text-color);
  
    --btn-text-font: 400 12px/1.67em var(--font-family);
    --btn-text-font-big: 400 16px/1.25em var(--font-family);
  
    --btn-paddings: 12px 9px;
  
    --btn-inactive-opacity: 0.5;
  }
  
  @page { 
    size: auto; 
    margin: 25mm 25mm 25mm 25mm;  
  } 


  *{
    page-break-inside: auto;
    opacity: 1 !important;
  }

  .echarts-for-react {
     filter: contrast(200%)
  }

  .echarts-for-react div {
    filter: brightness(80%)
  }
  
  
}