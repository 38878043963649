.tablesContainer{
  flex:1;
}

.tablesContainer:nth-child(1){
  margin-right:60px
}
.title{
  font-size: 22px;
  margin-bottom: 10px;
}

.tableContainer{
  min-height: 520px;
}
