.form {
  display: grid;
  grid-template-columns: 204px 132px min-content;
  grid-column-gap: 8px;
  align-items: end;
}

.noteField{
  grid-column: span 5;
}
