.table{
  overflow:hidden;
}

.modalOuterWrapper{
  align-item:center;
  display: flex;
  justify-content: center;
  flex:1
}

.modalContainer{
  :global(.rs-modal-content){
    background: var(--dark);
    width:500px;
    margin:auto;
    align-item:center;
  }
}

.modalWrapper{
  min-height: 150px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-content: center;
  background: var(--dark);

  .addRow{
    flex-direction: column;
    justify-content: flex-start;
  }

  .selectWrapper{
    width:300px;
    margin:10px;
  }
}

.amountWrapper{
  font-size:12px;
  font-weight: bold;
}

.formFooter {
  margin-bottom:1.2rem;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
}

.dropDown{
  margin-left:-6px
}

.inputWrapper{
  align-items: center;
}
.inputWrapper.error{

  input{
   border:1px solid red;
  }
}

.systemPrice{
  font-size: 11px;
  max-width: 100px;
  margin-left: 10px;
  white-space: initial;
}

.inputWrapper{
  max-width: 100px;
  margin-top: -5px;
  input{
    font-size: 11px;
    height: 30px;
  }
}

.selectWrapper{
  min-width: 200px;
  margin-right:10px;

  a{
    border:1px solid #0F141A!important;
    border-radius: 4px!important;
    padding:7.5px!important;
    font-size: 12px;
    span{
      font-weight:300!important;
    }
  }

}

.cellRow{
  display:flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: space-between;
}

.closeIconContainer{
  position: absolute;
  right:10px;
  top:10px;
display: flex;
}

.closeIcon{
  font-size:16px;
  color:var(--cloudy-blue);
  cursor:pointer;
}

.loaderContainer{
  background-color: #24293c;
  justify-content: center;
  display:flex;
  flex:1;
  padding:10px;
}

.addText {
  margin-right: 10px;
}
.addRow{
  color:var(--text-light-color);

  display:flex;
  flex:1;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  align-content: center;
  align-items: center;
  margin-top:10px;
  margin-bottom: -20px;
  span{
    margin-top:2.5px;
    font-weight:bold
  }
}

.addIcon{
  margin-right:10px;
  font-size:20px;
}

.saveButton{
min-width: 80px!important;
  margin-left:20px
}

.clearButton{
  min-width: 80px!important;
  border:0!important;
  margin-left:20px
}
