$primary: #7B94ED;
@import "~@progress/kendo-theme-default/dist/all.scss";


.k-dateinput {
  font-size: 0.75rem;
}

.k-animation-container.k-animation-container-relative.k-chart-tooltip-wrapper.k-animation-container-shown {
  pointer-events: none;
  margin-left: 4px;
}
