.legendRow{
  display:flex;
  flex-direction:row;
  margin-top:20px;
  margin-bottom:20px
}

.progressRow{
  display:flex;
  flex-direction:row;
  justify-content: space-between;
  margin-top:60px;
}

.separator {
  text-align:center;
  display:flex;
  flex-direction: row;
  justify-content: center;
  h1{
    font-size:34px
  }
}
