.infoBlock {
  display: flex;
  flex:1;
  align-items: center;
}

.infoBlockImage {
  margin-right: 7px;
}

.infoBlockContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.infoBlockTitle {
  font-size: 12px;
  font-weight: normal;
  color: var(--text-color);
  margin-top: 5px;
  white-space: nowrap;
}

.infoBlockValue {
  font-weight:500
}
