.gridSurround {
    display: flex;
    flex-direction: column;
    margin-top: 0.5rem;
}

.gridSurround li {
    list-style-type:none;
}

.section {
    background-color: var( --bg-dark);
    margin: 0.5rem 0rem;
    padding: 0.5rem;
    border-radius: 0.5rem;
}