.container {
  --radio-bg: var(--dark-blue);
  --radio-size: 12px;
  --radio-indicator-size: 6px;

  cursor: pointer;
  display: flex;
  align-items: center;
}

.radioControl {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkMark {
  position: relative;
  display: inline-block;
  margin-right: 12px;
  height: var(--radio-size);
  width: var(--radio-size);
  background-color: var(--radio-bg);
  border-radius: 50%;

  &:after {
    content: "";
    position: absolute;
    display: none;
    top: 25%;
    left: 25%;
    width: var(--radio-indicator-size);
    height: var(--radio-indicator-size);
    border-radius: 50%;
    background: white;
  }

  &.checked:after {
    display: block;
  }
}
