.settingsWrapper {
  padding: 8px 50px 40px;
  margin: -8px -50px 30px;
  background: var(--selected-bg);
}

.configWrapper {
  margin-top: 37px;
}

.profitWrapper {
  height: 260px;
}


.totalPL {
  display: flex;
  max-height: calc(100% - 35px);
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background: var(--selected-bg);
}

.totalValue {
  font-size: 18px;
  margin-top: 5px;
  color: var(--text-light-color);
  font-weight: 500;
}

.profitChart {
  flex-grow: 1;
  margin-right: 30px;
}

.totalImage {
  margin-bottom: 16px;
  width: 84px;
  height: 84px;
  background: url('../../../../static/images/dashboard/invest.svg') no-repeat;
  background-size: cover;
}
