.clear :global(.rs-table-cell) {
  background-color: transparent;
  color: white;
}

.blotterTable :global(.rs-table-cell-header) :global(.rs-table-cell-content){
  background-color: black;
}

.blotterTable :global(.rs-checkbox-wrapper) {
  top: 0;
  left: 0;
}

.tableRowWrapper {
  height: 24px;
}

.tableRow {
  cursor: pointer;
  &:active, &:focus {
    outline: none;
    border-radius: 2px;
    box-shadow: inset 0 0 0 2px var(--primary-color);
  }
}

.textWarning {
  color: var(--text-warning-color);
}

.hintButton {
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url('../../../../../../static/images/valid.svg') no-repeat;
  &.error {
    background: url('../../../../../../static/images/alert.svg') no-repeat;
  }
  &.warning {
    background: url('../../../../../../static/images/warning.svg') no-repeat;
  }
  &.info {
    background: url('../../../../../../static/images/attention.svg') no-repeat;
  }

}

.removeButton {
  margin-left: 10px;
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url('../../../../../../static/images/close.svg') no-repeat;
  background-size: 10px 10px;
  background-position: center;
  transition: opacity 0.1s ease-in-out;
  &:hover {
    opacity: 0.5
  }
}

.hintText {
  display: flex;
  align-items: center;
  &.error {
    color: var(--alert);
  }
  &.warning {
    color: var(--earth);
  }
}
.hintList {
  list-style: none;
  &Item {
    margin: 4px 10px;

    &.error {
      color: var(--alert);
    }
    &.warning {
      color: var(--earth);
    }
  }
}

.actionsContainer {
  justify-content: flex-end;
  display: flex;
  width: 100%;
  position: static;
  overflow: visible;
}

.actionsContainer a {
  display: flex;
}

.positionFix {
  background: red;
  margin-left: -110px !important;
  margin-top: -120px !important;
}

.tableColumn {
  &:not(:nth-child(-n+2)) {
    * {
      text-align: right;
      width: 100%;
      padding-right:10px;
    }
}
}

.tableColumn {
  &:last-child {
    * {
      padding-right:0;
    }
}
}

.headerContainer:not(:nth-child(-n+2))
 {
  div {
    text-align: right;
    width: 100%;
  }

}
