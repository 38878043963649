.clearButton {
    position: absolute;
    right: 0.3rem;
    height: 100%;
    padding-top: 0.5rem;
    display: flex;
    align-items: center;
    opacity: 25%;
}
.clearButton:hover {
    opacity: 100%;
    cursor: pointer;
}

.comboContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
}

.comboInput {
    border-radius: 8px;
    border: var(--input-border);
    background-color: var(--bg-dark);
    padding: 0rem 0.5rem;
    color: white;
    margin: 0rem;
    width: 100%;
    height: 2rem;
}
.comboInput.open{
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.comboItem {
    list-style: none;
    padding: 0rem 0.5rem;
    height: 1.5rem;
    line-height: 1.5rem;
    overflow-x: hidden;
    overflow-y: hidden;
}

.comboItemSurround {
    background-color: var(--bg-dark);
    position: absolute;
    width: 100%;
    min-width: 6rem;
    top: 2.6rem;
    box-shadow: 1px 1px 4px 0 rgba(0,0,0,.2);
    border-radius: 4px;
    z-index: 1000;
    max-height: 10rem;
    overflow-y: auto;
}

/*.label {*/
/*    position: absolute;*/
/*    padding: 0rem 0.5rem;*/
/*}*/

.open{} /* Must not contain any properties. Only being used as an additional selector for other classes*/
