.indicator {
  cursor: pointer;
  font-size:10px;
  display: flex;
  height:20px;
  width:20px;
  border-radius: 10px;
  border: 1px solid var(--text-color);
  align-items: center;
  justify-content: center;
  margin:0 auto;
  align-self: flex-end;
}
