.valueItemWrapper {
  --bg-color: rgba(102, 119, 204, 0.1);
  --text-color: var(--text-white);

  --text-fall-color: var(--text-alert-color);
  --text-rise-color: var(--text-success-color);

  position: relative;
  display: flex;
  padding: 4px;
  line-height: 2em;
  margin-top: 2px;
  border-radius: 2px;

  background: var(--bg-color);
  color: var(--text-color);
  pointer-events: none;

  &.bigger {
    margin-bottom: auto;
  }

  &.clicked {
    pointer-events: auto;
    cursor: pointer;
  }

}

.itemName {
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.itemValue {
  flex-grow: 0;
  margin-left: 15px;
  flex-shrink: 0;
  text-align: right;
}

.itemDiff {
  flex-grow: 0;
  flex-shrink: 0;
  padding-left: 15px;
  margin-left: 15px;
  text-align: right;
  color: var(--text-fall-color);
  font-weight: 500;
  background: url('../../../../static/images/fall.svg') no-repeat left center;

  &.profit {
    color: var(--text-rise-color);
    background: url('../../../../static/images/rise.svg') no-repeat left center;
  }
}


.connector {
  position: absolute;
  top: 50%;
  left: -5px;
}
