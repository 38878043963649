.legend {

  display: flex;
  margin-bottom: 8px;

  &.left {
    justify-content: flex-start;
  }

  &.right {
    justify-content: flex-end;
  }

  &.center {
    justify-content: center;
  }
}

.legendItem {
  display: flex;
  align-items: center;
  margin-left: 16px;

  &:first-child {
    margin: 0;
  }
}

.legendItemName {
  color: var(--text-color);
  font-size: 14px;
}

.legendItemValue {
  display: inline-block;
  margin-left: 8px;
  color: var(--value-color);
  font-size: 14px;
}

.legendItemColor {
  margin-right: 8px;
  line-height: 18px;

  &.bar {
    width: 18px;
    height: 18px;
  }

  &.line {
    position: relative;
    padding: 1px 9px;

    &:after {
      content: "";
      width: 8px;
      height: 8px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      background: inherit;
    }
  }
}

.legendItemColorImg {
  width: 100%;
  height: 100%;
}
