.recommendationList {
  --buy-color: #3c4871;
  --sell-color: #914d47;

  margin-bottom: 20px;
}

.recommendationItem {
  margin: 6px 6px 0 0;
}

.recommendationItemBuy {
  --btn-bg-color: var(--buy-color);
}

.recommendationItemSell {
  --btn-bg-color: var(--sell-color);
}

.country {
  margin-left: 3px;
}
