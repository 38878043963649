.comboItem {
  list-style: none;
  padding: 0rem 0.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  overflow-x: hidden;
  overflow-y: hidden;
  font-size:11px;
  cursor:pointer;
}

.comboContainer {
  font-size:13px;
  display: flex;
  flex-direction: column;
  width: 60px;
  position: relative;
}


.comboItemSurround {
  background-color: var(--bg-dark);
  position: absolute;
  width: 100%;
  min-width: 6rem;
  top: 2.6rem;
  box-shadow: 1px 1px 4px 0 rgba(0,0,0,.2);
  border-radius: 4px;
  z-index: 1000;
  max-height: 10rem;
  overflow-y: auto;
}
