.itemContainer{
  display: flex;
  cursor: pointer;
  flex-direction: row;
  margin-top:10px
}

.legendItemName {
  color: var(--text-color);
  font-size: 9px;
}

.legendItemValue {
  display: inline-block;
  margin-left: 8px;
  color: var(--value-color);
  font-size: 9px;
}

.legendColor {
  height:15px;
  width:15px;
  margin-right: 10px;
  margin-left: 10px;
  border-width:2.5px;
  border-color:white;
  border-style: solid;
  border-radius: 2px;
}
