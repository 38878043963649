.actionsButtonSurround {
  position: absolute;
  top: 82px;
  right: 0;
  //z-index: 10000;
}

.pencilAppear{
  cursor:pointer;
  margin-left:5px;
}

.delTradeBtn {
  display: none;
  background: url('../../../../../../static/images/close.svg') center center no-repeat;
  width: 18px;
  height: 18px;
  transition: all 1s ease;
}

//.tableColumn span {
//      text-align: right !important;
//      width: 100%;
//      padding-right:10px;
//
//}
.tableColumn {
  &:not(:nth-child(-n+3)) {
    span {
      text-align: right;
      width: 100%;
      padding-right:10px;
    }
}
}

.tableColumn:nth-child(11) {
text-align: right;
}

.headerContainer:not(:nth-child(-n+3))
 {
  div {
    text-align: right;
    width: 100%;
  }
}

.popover {
  min-width: 30px;
}

.tableRow:hover {
  .delTradeBtn {
    display: block;
  }
}

.tooltipContainer {
  min-width: 180px;
  max-width: 450px;
}
.tooltipItem span {
  display: inline-block;
  width: 120px;
  font-weight: bold;
}
.tooltipNotesItem {
  margin-top: 10px;
}
.tooltipNotesItem span {
  font-weight: bold;
}
