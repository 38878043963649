.btn {
  /* --btn-bg-color: var(--primary-color);

  --btn-border-color: var(--btn-bg-color);
  --btn-outline-border-color: var(--cloudy-blue);

  --btn-border-radius: 4px;

  --btn-text-color: var(--text-white);
  --btn-outline-text-color: var(--text-color);

  --btn-text-font: 400 12px/1.67em var(--font-family);
  --btn-text-font-big: 400 16px/1.25em var(--font-family);

  --btn-paddings: 12px 9px;

  --btn-inactive-opacity: 0.5; */
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
  border-radius: var(--btn-border-radius);
  color: var(--btn-text-color);
  display: inline-block;
  font: var(--btn-text-font);
  padding: var(--btn-paddings);
  cursor: pointer;
  opacity: var(--btn-inactive-opacity);
  transition: background-color 200ms ease-out;
  &:focus, &:active {
    outline: none;
  }

  &.active {
    opacity: 1;
  }

  &.disabled {
    opacity: 0.4;
  }

  &.outline-disabled {
    --btn-text-color: var(--btn-outline-text-color);
    --btn-border-color: var(--dark-three);
    --btn-inactive-opacity: 1;

    background-color: transparent;
    &.active {
      opacity: 1;
      --btn-border-color: var(--dark-three);
    }
  }

  &.outline {
    --btn-text-color: var(--btn-outline-text-color);
    --btn-border-color: var(--btn-outline-border-color);
    --btn-inactive-opacity: 1;

    background-color: transparent;
    &.active {
      opacity: 1;
      --btn-border-color: var(--warm-blue);
      border-top: 4px solid var(--warm-blue);
    }
  }

  &.outline-warning {
    --btn-text-color: var(--text-white);
    --btn-border-color: var(--cocoa);
    --btn-inactive-opacity: 1;
    background-color: var(--cocoa);
  }

  &.outline-info {
    --btn-text-color: var(--text-dark);
    --btn-border-color: var(--confirm);
    --btn-inactive-opacity: 1;
    background-color: var(--confirm);
  }


  &.empty {
    --btn-paddings: 0;
    border: 0;
    background-color: transparent;
  }

  &.dark {
    --btn-paddings: 0;
    border-color: var(--dark-two);
    background-color: var(--dark-two);
  }

  &.light {
    --btn-paddings: 0;
    background-color: var(--primary-color);
    transition: background-color  200ms ease-out, border-color 200ms ease-out;
    font-weight: 700;
    &:hover {
      box-shadow: 0px 0px 10px var(--primary-color);
    }
  }

  &.xsmall {
    --btn-paddings: 0px;
    min-width: 66px;
  }

  &.small {
    --btn-paddings: 6px;
    min-width: 88px;
  }


  &.big {
    --btn-text-font: var(--btn-text-font-big);
  }
}
