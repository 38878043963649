.react-datepicker-popper {
  --calendar-bg: var(--secondary-bg);

  --calendar-text-color: var(--light);
  --calendar-days-color: var(--cloudy-blue);
  --calendar-separator-color: rgba(169, 178, 209, 0.1);

  --calendar-selected-day-bg: var(--warm-blue);

  --calendar-today-bg: var(--dark-blue);
  --calendar-today-color:var(--text-white);

  --calendar-range-color: var(--calendar-selected-day-bg);
}

.react-datepicker-popper[data-placement^="bottom-end"] .react-datepicker__triangle {
  left: calc(100% - 20px);
}

.react-datepicker-popper[data-placement] .react-datepicker__triangle,
.react-datepicker-popper[data-placement] .react-datepicker__triangle::before {
  border-bottom-color: var(--calendar-bg);
  border-top-color: var(--calendar-bg);
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  width: 100%;
}

.wm-datepicker {
  border: none;

  .react-datepicker__header {
    padding-top: 10px;
    background: var(--calendar-bg);
    border-color: var(--calendar-separator-color);
  }

  .react-datepicker__day-names {
    margin-top: 14px;
  }

  .react-datepicker__month-container {
    background: var(--calendar-bg);
    border-radius: 4px;
  }

  .react-datepicker__current-month {
    font-size: 13px;
    font-weight: 500;
    color: var(--calendar-text-color);
    line-height: 24px;
  }

  .react-datepicker__day,
  .react-datepicker__day-name {
    cursor: pointer;
    color: var(--calendar-days-color);
  }

  .react-datepicker__day--today {
    color: var(--calendar-today-color);
    background: var(--calendar-today-bg);
    border-radius: 4px;
  }

  .react-datepicker__day--outside-month {
    opacity: 0.4;
  }

  .react-datepicker__day:hover,
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__day--range-start,
  .react-datepicker__day--range-end,
  .react-datepicker__day--in-range,
  .react-datepicker__day--selected {
    background: var(--calendar-selected-day-bg);
    color: var(--calendar-text-color);
  }

  .react-datepicker__month--selecting-range .react-datepicker__day--in-range {
    background: var(--calendar-range-color);
  }

  .react-datepicker__day--disabled {
    opacity: 0.3;
  }

  .react-datepicker__day--disabled:hover {
    background: none;
  }

  .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
    color: var(--calendar-text-color);
  }

  .react-datepicker__navigation {
    border: none;
    background-image: url('../images/arrow-right.svg');
    background-size: contain;
    width: 24px;
    height: 24px;
  }

  .react-datepicker__navigation--previous {
    transform: rotate(-180deg);
  }
}
