.scenariosItems{
  display: flex;
  margin: 0 -12px;
}

.scenariosItemsColumn{
  flex-direction: column;
}

.scenariosItem{
  flex-grow: 1;
  padding: 0 12px;
  margin-bottom: 12px;
}
