.wrapper {
  border-radius: 6px;
  padding: 15px;
  margin:5px;
  background: var(--bg-darkest);
  filter: drop-shadow(0 0 5px var(--bg-darkest));
  width:25%;
}

.cardsContainerEmpty{
  display: flex;
  margin-top:5%;
  flex:1;
  justify-content: center;
  align-content: center;
  align-items: flex-start;
}

.arrowImage{
  display:flex;
  flex:1;
  justify-content: flex-end;
  img{
    margin-top:15px;
    height:32px;
    width:32px
  }
}

.title{
  font-size:14px;
  color:white;
}
