:root {
  --darkgray: #70778f;
  --light: #d3d8eb;
  --light-dark:#1C202E;
  --light-gray:#3c3f43;
  --chart-1: #6677cc;
  --chart-2: #c2b261;
  --chart-3: #59abb3;
  --chart-4: #cc6f66;
  --chart-5: #56b877;
  --chart-6: #cc66aa;
  --chart-7: #85b861;
  --chart-8: #a266cc;
  --alert:#FF6972;
  --confirm: #41d9a0;
  --warm-blue: #4162d9;

  --dusk: #3c4871;
  --earth: #946c3d;
  --earth-light: #F2D647;
  --selected:#2E3D71;
  --cocoa: #914d47;
  --dark: #23283d;
  /*--light-blue: #6677cc;*/
  --light-blue:#7B94ED;
  --dark-blue: #343a54;
  --blue-grey: #757fa1;
  --black-10: rgba(0, 0, 0, 0.1);
  --cloudy-blue: #a9b2d1;
  --warm-grey: #9a9a9a;
  --dark-two: #161926;
  --dark-three: #23293d;
  --dark-four:#0d1222;
  --off-white: #f2f2ff;

  --font-family: 'Nunito' !important;
  /*--bg-color: black;*/
  /*--bg-color: #171b24;*/
  --bg-color: #0F141A;
  --secondary-bg: var(--dark);
  --selected-bg: #161a25;

  --primary-color: var(--warm-blue);

  --border-color: var(--cloudy-blue);

  --text-size: 11px;
  --text-title-size: calc(var(--text-size) + 2px);

  --text-color:#E2E6F4;
  --text-dark: var(--dark);
  --text-light-color: var(--light);
  --text-disabled-color: var(--darkgray);
  --text-alert-color: var(--alert);
  --text-success-color: var(--confirm);
  --text-warning-color: var(--earth-light);
  --text-white: #fff;
  --text-on-secondary-bg: var(--text-color);


  --xs-text: 400 12px/1.17em var(--font-family);
  --small-text: 400 12px/1.33em var(--font-family);
  --medium-text: 400 16px/1.5em var(--font-family);
  --medium-bold-text: 500 18px/1.25em var(--font-family);

  --small-title: 500 14px/1.38em var(--font-family);
  --medium-title: normal 26px/1.33em var(--font-family);

  --tab-color: var(--text-color);
  --tab-active-color: var(--primary-color);
  --tab-inactive-color: var(--light);
  --tab-disabled-color: rgb(175, 175, 175);
  --content-text-color: var(--text-color);
  --content-background: rgba(0, 0, 0, 0.1);
  --content-paddings: 20px 30px;
  --value-color: var(--text-white);


  --btn-bg-color: var(--primary-color);
  --btn-border-color: var(--btn-bg-color);
  --btn-outline-border-color: var(--cloudy-blue);
  --btn-text-color: var(--text-white);
  --btn-outline-text-color: var(--text-color);

  --btn-border-radius: 4px;
  --btn-text-font: 400 12px/1.67em var(--font-family);
  --btn-text-font-big: 400 16px/1.25em var(--font-family);
  --btn-paddings: 12px 9px;

  --btn-inactive-opacity: 0.5;

  --gradient-dark: linear-gradient(var(--bg-darkest), var(--bg-dark));
  --gradient-jack-dorsey:linear-gradient(315deg, #0cbaba 0%, #380036 74%);


  /* Asset Class colors */
  --color-equities: var(--chart-3);
  --color-crypto: var(--light);


  --esma-band1-color: #41d9a0;
  --esma-band2-color: #00b26f;
  --esma-band3-color: #d9bf41;
  --esma-band4-color: #fb9900;
  --esma-band5-color: #e17300;
  --esma-band6-color: #e13800;
  --esma-band7-color: #a50009;
  --benchmark-color: #3863ff;

  /* Extended */
  --shaded: #373b4d;
  --bg-dark: #0A0F1A;
  --bg-darkest: #070b14;
  --input-border: solid 1px var(--shaded);
  --inactive: #747b96;

  --purple:#b481d9;
  /* AWS Amplify */
  --amplify-primary-color: var(--chart-1);
  --amplify-primary-tint: var(--bg-color);
  --amplify-primary-shade: var(--darkgray);
  --amplify-font-family: 'Roboto', 'Helvetica', sans-serif;

  --link: #399BD1
}
