.wrapper{
  margin-top: 40px;
  margin-bottom:40px;
}

.title{
  font-size:22px;
  margin-bottom:10px
}

.tablesContainer {
  display: flex;
  flex-flow: row;

  :nth-child(2){
    margin-left: 20px;
  }

}

.tablesContainer h3 {
  margin: 10px;
  margin-left: 0;
}

.dataTable {
  border-width: 0;
border-radius: 8px;
}

.dataTable:not(:first-child) {
  margin-left: 0px;
}

.dataTable tr:nth-child(even) {
  background-color: #24293C;
}

.dataTable tr:nth-child(odd) {
  background-color: rgb(28, 32, 46);
}

.dataTable td {
  width: 70px;
  min-width: 50px;
  text-align: right;
  padding: 5px;
  margin: 0 1px 0 0;
}

.dataTable td:first-child {
  text-align: left;
  width: 300px;
  min-width: 150px;
}
