.chartLegendContainer {
  display: flex;
  flex: 1;
  flex-wrap:wrap;
  /* flex-direction: row-reverse;*/
  margin-top: 20px;
  margin-right: 30px;
  margin-bottom: -30px;
}
.legendItem {
  display: flex;
  flex-direction: row;
  margin-right: 20px;
  margin-bottom:5px;

}
.portfolioSymbol {
  display: flex;
  width: 14px;
  height: 14px;
  background-color: #6677cc;
  margin-right: 5px;
  margin-left: 30px;
  border-radius: 50%;
  box-sizing: border-box;
}
.benchmarkSymbol {
  display: flex;
  width: 12px;
  height: 12px;
  background-color: #138eb0;
  margin-right: 10px;
  box-sizing: border-box;
  transform: rotate(45deg)
}
.longPositionSymbol {
  display: flex;
  width: 14px;
  height: 14px;
  background-color: #59abb3;
  margin-right: 5px;
  border-radius: 50%;
  box-sizing: border-box;
}
.shortPositionSymbol {
  display: flex;
  width: 0;
  height: 0;
  border-left: 14px solid transparent;
  border-right: 14px solid transparent;
  border-top: 14px solid #cc6f66;
  /*width: 14px;
  height: 14px;
  background-color: #cc6f66;
  margin-right: 5px;
  border-radius: 50%;
  box-sizing: border-box;*/
}
.labelItem {
  display: flex;
  font-size: 14px;
}
.chartTitle {
}
.chartPeriodText {
  display: inline-block;
  top: 5px;
}
