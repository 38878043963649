.container{
  display: flex;
  flex:1;
  justify-content: flex-end;

  div{
    width:200px;
    height:130px;
  }
}
