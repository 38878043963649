.selectorsContainer {
  display: flex;
  flex-flow: row;
  justify-content: flex-end;
}
.selectWidget {
  width: 33%;
  display: flex;
  justify-content: flex-end;
  text-align: right;
}
.selectLabel {
  padding-top: 11px;
  padding-right: 5px;
}
.dropdownBox {
  min-width: 100px;
}
