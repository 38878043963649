.container{
  display:flex;
  flex-direction: column;
  margin-top:0px;
  align-items: center;
  text-align: center;
}

.esg{
  font-size: 28px;
  font-weight: bold;
  color:#6F9ABE;
  letter-spacing: 10px;
  transition: opacity 1.5s;
}

.iconContainer{
  width:80px;
}

.esgElementText{
  font-size: 28px;
  font-weight: bold;
  letter-spacing:5px;
  margin-left: 15px;
  margin-top:2.5px;
}


.esgElement{
  cursor: pointer;
  margin-top:78px;
  display:flex;
  flex-display: row;
  align-items: center;
  align-content: center;
  transition: opacity 1.5s;
}

