.selectWrapper{
  min-width: 200px;
  a{
    border-radius: 4px!important;
    padding:6.5px!important;
    border:none!important;
    font-size: 12px;
    span{
      font-weight:300!important;
    }
  }

}
