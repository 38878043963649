.dashboardInfo {
  display: flex;
  max-width: 1024px;
  max-height: 56px;
  flex-shrink: 0;
  margin:28px 0;
}

.dashboardInfoItem {
  --border-color: rgba(102, 119, 204, 0.2);

  display: flex;
  padding-right: 12px;
  margin-right: 6px;

  &:last-child {
    border: none;
    padding-right: 0;
    margin-right: 0;
  }

  &.dashboardInfoItemStart {
    justify-content: flex-start;
  }
}

.chartBox {
margin-top:40px
}
