.borderBottom {
  border-bottom: 1px solid var(--text-color);
}



.item {
  cursor: pointer;
  padding: 0.2rem 0.4rem;
}
.item:hover {
  font-weight: bold;
}

.item.disabled {
  cursor: not-allowed;
}
.item.disabled:hover {
  font-weight: normal;
}

.itemInner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
