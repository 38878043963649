.donutContainer {
  margin:auto;
  height: 330px;
}
.donutContainer svg {
  overflow:unset!important;
}

.donutContainerExpanded {
  width:800px;
  height:800px
}
