
.assetsDashboardGrid {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.line {
  margin: 40px 0 20px 0;
  border-bottom: 1px solid var(--border-color);
}

.InfoContainer {
  width: 100%;
  justify-items: start;
  display: flex;
  flex-wrap: wrap;
}
.defaultContainer {
  width: 100%;
}
.containerRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 10px;
}
.containerColumn {
  display: flex;
  flex-direction: column;
  /*width: 45%;*/
  height: 550px;
  /*flex-basis: 100%;*/
  flex: 1;
  padding-right: 1.5rem;
  /*box-sizing: border-box;*/
}

.item {
  margin-right: 20px;
  white-space: nowrap;
  margin-bottom: 20px;
}

.value {
  margin-left: 10px
}

.closeBtn {
  margin-top: 12px;
  font-size: 18px;
  color: var(--cloudy-blue);
  cursor: pointer;
}

.closeBtn a {
  text-decoration: none;
}

.closeBtn:hover {
  opacity: 0.9
}
