.defaultContainer {
  width: 100%;
}
.containerRow {
  display: flex;
  width: 100%;
  margin-bottom: 15px;
  min-height: 50px;
}
.containerColumn {
  display: flex;
  flex-direction: column;
  height: 350px;
  flex-basis: 100%;
  flex: 1;
  padding: 10px;
  box-sizing: border-box;
}
.periodContainer {
  float: right;
  margin-bottom:1rem;
  margin-top:-4rem;

}
.scoreChartContainer {
  display:flex;
  justify-content: center;
  flex:1;
  flex-direction: column;
  height: 500px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.simulatedReturnChartContainer {
  flex:1;
  text-align: center;
  height: 500px;
  padding: 10px;
}
.returnVsRiskScatterChartContainer {
  min-width: 39%;
  flex: 1;
  box-sizing: border-box;

}
.rollingVolatilityChartContainer {
  flex: 1;
  min-width: 39%;
  padding: 10px;
}
.contributionToVolatilityChartContainer {
  height: 550px;
  width: 100%;
  padding: 10px;
}
.historicTopTenVolatilityChart {
  min-width: 39%;
  flex: 1;
  box-sizing: border-box;
  padding: 10px;

}

.dashboardTitleContainer {
  margin-top: 10px;
  margin-bottom: 10px;
}
.dashboardTitle {
  margin: 10px 0;
}

.dashboardInfo {
  display: flex;
  max-width: 1024px;
  max-height: 56px;
  flex-shrink: 0;
  margin:28px 0;
}

.dashboardInfoItem {
  --border-color: rgba(102, 119, 204, 0.2);

  display: flex;
  padding-right: 24px;
  margin-right: 14px;
  border-right: 1px solid var(--border-color);

&:last-child {
   border: none;
   padding-right: 0;
   margin-right: 0;
 }

&.dashboardInfoItemStart {
   justify-content: flex-start;
 }
}


