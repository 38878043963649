//.columnFilterContainer {
//  position: absolute;
//  right: 0px;
//  top: 60px;
//  width: 115px;
//  //z-index: 1000;
//}

.filterToggleButton {
  margin-right: 0;
  width: 120px;
  padding-left: 20px;
  height: 16px;
  background-image: url('../../../../../../static/images/icons/columns.svg');
  background-repeat: no-repeat;
  background-position-x: 0;
  cursor: pointer;
}

.checklistVisible, .checklistHidden {
  min-width: 140px;
  margin-top: 2px;
  margin-right: 15px;
  overflow: auto;
  display: block;
  padding: 10px;
  background-color: var(--dark);
  border: 1px solid var(--text-color);
  position: absolute;
  z-index: 1;
}

.checklistHidden {
  display: none;
}

.checklistItem {
  padding-top: 5px
}
