.border {
  height: calc(100% + 20px);
  top: -15px;
  width: calc(100% + 10px);
  left: -5px;
  border: 1px solid var(--light);
  position: absolute;
  border-radius: 10px;
  background: none;
  z-index: -1;
}

.currencyLabel {
  padding: 10px;
}

.field {
  margin: 0 5px;
  width: 160px;
}
.field.wide {
  width: 160px;
}

.horizontal {
  display: flex;
  position: relative;
}

.label {
  position: absolute;
  top: -20px;
  left: 5px;
  background-color: var(--bg-color);
  padding: 0 5px;
}

.middle {
  width: 80px;
  /*height: 50px;*/
}

.legSurround {
  margin: 0 10px;
}

.rightAlign {
  text-align: right;
}

.side {
  width: 360px;
  padding: 10px;
}

.surround {

}

.tickBox {
  color: var(--text-success-color);
  /*background-color: white;*/
  cursor: pointer;
  /*padding: 0 10px;*/
}

.tickBox:hover {
  color: green;
}
