.formWrapper {
  margin-top: 40px;
}

.fieldWrapper {
  margin-bottom: 23px;
}


.nameInp {
  width: 70%;
  margin: 0 auto;
}

.typeList {
  list-style: none;
  display: flex;
  margin: 40px -15px 56px;
}

.typeItem {
  --border-color: transparent;

  width: 209px;
  margin: 0 15px;
  text-align: center;
  background: var(--selected-bg);
  border: 2px solid var(--border-color);
  border-radius: 4px;
  background-repeat: no-repeat;
  background-position: center 60px;


&:hover,
&.active {
   --border-color: var(--primary-color);
 }

&.personal {
   background-image: url('../../static/images/personal-portfolio.svg');
 }

&.model {
   background-image: url('../../static/images/model-portfolio.svg');
 }

&.client {
   background-image: url('../../static/images/client-portfolio.svg');
 }
}

.itemBtn {
  padding: 156px 15px 32px;
  color: var(--text-color);
}

.typeName {
  font: var(--medium-text);
}

.typeDescription {
  margin-top: 16px;
}
