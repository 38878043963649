.barContainer{
  flex:1;
  width:100%;
  max-height: 26px;
  background-color: var(--secondary-bg);
  border-radius: 6px;
}

.barContent{
  height:26px;
  border-radius: 6px;
  transition: width 1.5s;
}

.percentage{
  font-size:18px;
  opacity: 0;
  transition: opacity 2s;
  font-weight: 500;
}
