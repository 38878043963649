.instrumentFormWrapper {
  padding-top: 32px;
}

.titleBtn {
  margin-left: 8px;
}

.tabContent {
  flex-direction: column;
  flex: 1;
}
