.surround {
  margin-top: 10px;
  width: 100%;
}

.info {
  padding-top: 1rem;
  font-weight: bold;
}

.titleRow {
  border-bottom: 1px solid var(--light);
}

.row > .cell:last-of-type {
  padding-right: 0px;
  margin-right: 0px;
}

.titleCell {
  padding: 4px 4px 4px 0px;
  margin-bottom: 12px;
}


.titleLabel {
  color: var(--tab-active-color);
  padding-left: 6px;
}

.titleLabel.highlighted {
  color: var(--confirm);
  text-decoration-line: underline;
  font-weight: bold;
}

.titleLabel.shaded {
  color: var(--tab-disabled-color)
}

.cell {
  /*max-width: 20px;*/
  /*background-color: pink;*/
  background-color: var(--dark);
  border-radius: var(--btn-border-radius);
  height: 27px;

}


.cellText {
  background-color: var(--dark);
  border-radius: var(--btn-border-radius);
  border-color: transparent;
  padding: 6px;
  color: var(--text-light-color);
  width: 100%;
}

.cellText.overdue {
  border-color: orange;
}

.cellText.overdue-bad {
  border-color: var(--alert);
}

.table {
  /*border-spacing: 0.4rem 0.3rem;*/
  margin-top: 1rem;
  /*border-collapse: separate;*/
  /*min-width: 80%;*/
  width: 100%;
}

.thinPadding {
  padding: 0.2rem;
}

.cellPrice {
  padding: 0 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.priceText {
  margin-left: 6px;
  color: var(--text-success-color)
}

.textError {
  color: var(--text-alert-color)
}