.container, .bondContainer {
  min-width: 100%;
  border: 1px solid var(--dark-blue);
  display: grid;
  grid-template-columns: 340px  1fr  1fr  1fr 1fr  1fr  1fr 1fr 1fr;
}
.bondContainer {
  grid-template-columns: 340px  1fr  1fr  1fr 1fr  1fr  1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;

}
.shadow {
  box-shadow: 10px 0px 4px 0px rgba(0,0,0,0.1);
  position:relative;
}

.headerTitleContainer {
  height: 80px;
}

.subHeaderTitleContainer {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;

  &>h3{
    display: flex;
    align-items: center;
  }
}

.column {
  transition: min-width 0.5s;
  transition-timing-function: ease-in-out;

  border-left: 1px solid var(--dark-blue);
  min-width: 40px;

  &:not(:last-child) {
    margin-right: 5px;
  }

  &:not(:last-child) {
    border-right: 1px solid var(--dark-blue);
  }
}

.column h4 * {
  font-size: 12px;
}

.splitColumn h4 * {
  font-weight: bold;
  color: var(--cloudy-blue)\;
}

.splitColumn h5 {
  font-size: 11px;
}


.cell{
  display: flex;
  align-items: center;
  padding: 0 10px;
  min-height: 40px;

  &:not(:last-child) {
    border-bottom: 1px solid var(--dark-blue);
  }
}

.detailCell {
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  &>*{
    height: 100%;
    display: flex;
    align-items: center;
  }
}

.detailCell p {
  font-size: 13px;
  text-align: center;
}

.extraHeight {
  height: 170px
}

.titleContainer {
  grid-template-columns: 2.7fr 1.2fr 1.2fr;
  display: grid;
  width: 100%;

  &.twoSplits {
    grid-template-columns: 2.7fr 2.4fr;
  }

  &>*:first-child {
    padding-right: 5px;
  }
}

.splitColumn {
  grid-template-columns: 2.7fr 2.4fr;
  display: grid;
  width: 100%;

  &>*:first-child {
    padding-right: 5px;
  }
}




.details {
  display: grid;
  grid-template-columns: 1fr 1fr 0.7fr 1fr;
  width: 100%;
  margin-top: 10px;
  align-items: start;
  &>*:not(:last-child) {
    border-right: 1px solid var(--dark-blue);
    margin-right: 10px;
  }
}

.detailsSecondRow {
  grid-template-columns: 2fr 1.7fr;
}

.details h5 {
  color: var(--blue-grey)
}

.ratioHeader {
  background: var(--dark-three);
  justify-content: center;
  cursor: pointer;
  position: relative;
  height: 80px;
  text-align: center;

  &:hover {
    background: var(--dark);
  }
}

.totalHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px;
  height: 80px;

  &>* {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &>*:last-child {
    border-top: 1px solid var(--dark-blue);
  }
}

.neutral {
  background: gray
}
.negative {
  background: var(--alert)
}
.positive {
  background: var(--confirm)
}

.confirm {
  color: var(--confirm);
}

.error {
  color: var(--alert);
}

.blue * {
  color: var(--warm-blue);
}

.custodianFilter {
  width: 130px;
}

.settingsButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
