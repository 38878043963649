.avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.large {
  width: 100%;
  height: 100%;
}

.textAvatarContainer {
  color: var(--text-white);  
  line-height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 100%;
}