.tradesHeader {
  margin-top: 0;

  .titleBtn {
    margin-left: 8px;
    white-space: nowrap;
    &Offset {
      margin-left: 24px;
    }
    &Wide {
      --btn-paddings: 6px 28px;
    }
    .icon {
      margin-right: 8px;
    }

  }
}
.titleBlock {
  margin-top: 10px;
}

.titleBtnRightMargin {
  margin-right: 10px;
}

.icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-top: -2px;
  vertical-align: middle;
  background: 0 0 no-repeat;
  margin-right: 4px;

  &.import {
    background-image: url('../../../../../../static/images/excel.svg');
  }

}
.icon.preview {
  background-image: url('../../../../../../static/images/preview.svg');
}
.icon.error {
  background-image: url('../../../../../../static/images/imp-outline.svg');
}
//.icon
.icon.add {
  background-image: url('../../../../../../static/images/add.svg');
}

.description {
  margin-bottom: 32px;
  font: var(--medium-text);
}

.modesWrapper {
  //min-height: 240px;
  padding: 16px 0 32px;
  display: flex;
  width: 100%;
}

.tradesTableHeader {
  display: flex;
  justify-content: space-between;
  &Controls {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    &Errors {
      border-right: 1px solid var(--darkgray);
      padding: 0 17px;
      margin-right: 15px;
    }
  }
}

.tableWrapper {
  flex-grow: 1;
}

.summaryContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  margin-right: 10px;
}

.btnContainerOffset {
  margin-left: 10px;
  margin-right: 20px;
}

.summary {
  margin-left: 24px;

  &.withError {
    color: var(--text-warning-color);
  }
}

