.periodWrapper {
  display: flex;
  min-width: 180px;
}
.periodDetail {
  padding-left: 4px;
}
.periodLabel {
  background: url('../../static/images/period.svg') left center no-repeat;
  background-size: 24px;
  padding: 0 8px 0 30px;
  line-height: 24px;
}

.tabsList {
  list-style: none;
  display: flex;
  align-content: center;
}

.tabsItem {
  --border-color: transparent;

  margin: 0 5px -2px;
  display: flex;
  align-content: center;
  border-bottom: 2px solid var(--border-color);
  border-radius: 1px;

  &.active {
    --border-color: var(--cloudy-blue);
  }
}

.tabsBtn {
  font-size: var(--text-size);
}


.datePickers {
  display: flex;
  height: 24px;
  background: var(--bg-color);
  align-items: center;
}

.datePickersSeparator {
  display: inline-block;
  vertical-align: center;
  height: 50%;
  width: 1px;
  background: var(--border-color);
  opacity: 0.2;
}

.datePickerItem {
  border: none;
  background: transparent;
  width: 120px;
  margin: 0 5px;
  color: var(--text-white);
  padding: 0;
  font: var(--small-text);
}

