.datePickerWrapper{
  border:1px solid var(--light-gray);
  border-radius: 6px;
  padding-top:4px;
  padding-bottom:4px;
  background-color: #1A1D24;
}

.button {
  padding: 0.2rem;
  width: 6rem;
  font-size: 0.75rem;
  margin-bottom: 0.5rem;
  text-align: center;
  cursor: pointer;
}
.button.active {
  background-color: var(--primary-color);
}
.label {
  font-size: 1rem;
  margin-bottom: 1rem;
  display: block;
}

.horizontal {
  display: flex;
}

.inputsSurround {
  margin-left: 1rem;
  border-radius: var(--btn-border-radius);
}
.inputsSurround.open {
  background-color: var(--bg-darkest);
  padding: 0.5rem;
}


table.inputsTable td {
  padding-bottom: 0.5rem;
}


.moreSettings {
  cursor: pointer;
  text-align: right;
}

.navButton {
  padding: 0.5rem;
  cursor: pointer;
}
.navButton.active {
  background-color: white;
  color: var(--primary-color);
  font-weight: bold;
}

.navSurround {
  width: 10rem;
}


.rightAligned {
  text-align: right;
}

.noScrollbar::-webkit-scrollbar {
  display: none;  /* Safari and Chrome */
}

.surround {
  height: 100%;
  display: flex;
}

:global(.position-table-surround .rs-table-row-expanded){
  padding: 0;
}

:global(.position-table-surround .rs-table-row){
  cursor: pointer;
}
