
.left {
  height: 200px;
  background-color: var(--dark-three);
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  height: 200px;
}

.right {
  flex: 2;
  margin: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input {
  border-radius: 8px;
  border: var(--input-border);
  background-color: var(--bg-dark);
  padding: 0.4rem 0.5rem;
  color: white;
  margin: 0.5rem 0rem;
  /*flex: 1;*/
}

.largeOnly {
  display: none;
}

.logout {
  position: absolute;
  bottom: 20px;
  left: 20px;
}
.logout:hover {
  cursor: pointer;
}


.productSelector {
  width: 100%;
}

.productsSurround {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 2rem;

}

.row {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.smallOnly {
  display: block;
}

.spacer {
  width: 10px;
}

@media only screen and (min-width: 550px) {
  .largeOnly {
    display: block;
  }
  .productsSurround {
    display: flex;
    flex-direction: row;
  }
  .smallOnly {
    display: none;
  }
}

@media only screen and (min-width: 800px) {
  .productSelector {
    max-width: 600px;
  }
  .surround {
    display: flex;
    flex-direction: row;
    height: 100%;
  }

  .left {
    flex: 1;
    height: 100%;
  }

  .right {
    flex: 2;
    margin: 80px;
    justify-content: normal;
  }
}
