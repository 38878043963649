@import '../../../node_modules/rc-slider/dist/rc-slider.min.css';

.rc-slider {
  --rc-rail-color: var(--border-color);
  --rc-rail-height: 2px;

  --rc-handler-color: var(--primary-color);
  --rc-handler-border-color: #1c202e;
  --rc-handler-border-width: 3px;
  --rc-handler-size: 17px;
}

.rc-slider-rail {
  height: var(--rc-rail-height);
  background: var(--rc-rail-color);
}

.rc-slider-handle {
  position: relative;
  width: var(--rc-handler-size);
  height: var(--rc-handler-size);
  background: var(--rc-handler-color);
  border: var(--rc-handler-border-width) solid var(--rc-handler-border-color);
  box-sizing: content-box;
  margin-top: -9px;
}

.rc-slider-handle:hover, .rc-slider-handle:active, .rc-slider-handle.rc-slider-handle-click-focused {
  border-color: var(--rc-handler-border-color);
  box-shadow: none;
}


.rc-slider-disabled .rc-slider-handle {
  background-color: grey;
  border-color: #1c202e;
}

.rc-slider-track {
  display: none;
}

.rc-slider-step {
  top: -4px;
}

.rc-slider-step .rc-slider-dot {
  width: 1px;
  height: 3px;
  opacity: 0.3;
  background: var(--cloudy-blue);
  margin-left: 0;
  border: none;

}

