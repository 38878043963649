.allocationsList {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  margin: 20px -24px 0;
  &.wrap {
    flex-wrap: wrap;
  }
}

.allocationsListItem {
  padding: 0 24px 24px;
  flex: 1 1 100%;
  min-width: 25%;
  max-width: 33%;
}
