.widgets-block {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  margin-bottom: 40px;
  flex-shrink: 0;
  flex-basis: auto;

}
.widgets-block:last-child {
  margin-bottom: 0;
}

.widgets-block__item {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 1px;
  margin: 0 10px;
}

.widgets-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;
}

.widgets-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.widgets-content--row {
  flex-direction: row;
}
