.addCustodianBtn{
  background-color: var(--dark-blue);
  min-width: 55px;
  padding:2px;
  margin-top:20px;
  text-align: center;
  p{
    text-align: center;
    line-height: 13px;
    font-size: 10px;
  }
}
