.dropWrapper {
  height: 16px;
  position: relative;
}

.dropBtn {
  background: url('../../../static/images/cebab.svg') center center no-repeat;
  background-size: 24px;
  width: 16px;
  height: 16px;
  position: relative;

  &:before {
    position: absolute;
    content: '';
    top: -5px;
    right: -5px;
    left: -5px;
    bottom: -5px;
  }
}

.dropContent {
  --content-radius: 4px;
  --content-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.26);
  --content-bg: var(--dark-two);

  display: flex;
  flex-direction: column;
  position: absolute;
  min-width: 200px;
  z-index: 2;
  padding: 4px 0;
  border-radius: var(--content-radius);
  box-shadow: var(--content-shadow);
  border: solid 1px var(--content-bg);
  background-color: var(--dark-two);
  max-height: 250px;
  overflow: auto;
  overflow-x: hidden;
  &:hover {
    cursor: pointer;
  }
}


.drop-down__item {
  padding: 5px 28px 5px 16px;
  text-align: left;

  &:hover {
    background-color: var(--secondary-bg);
  }
}

.drop-down__separator {
  height: 1px;
  margin: 3px 5px;
  background: var(--dark);
}


.row {
  padding: 6px;
}
.row.alternate {
  background-color: var(--secondary-bg);
}
.row:hover {
  background-color: var(--darkgray) !important;
}
