.rs-popover{
  --content-radius: 4px;
  --content-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.26);
  --content-bg: var(--dark-two);
  display: flex;
  flex-direction: column;
  position: absolute;
  min-width: 200px;
  max-width: 300px;
  z-index: 1;
  padding: 4px 0;
  border-radius: var(--content-radius);
  box-shadow: var(--content-shadow);
  border: solid 1px var(--content-bg);
  background-color: var(--dark-two);
  max-height: 250px;
  overflow: auto;
  overflow-x: hidden;
}
.rs-popover.narrow {
  min-width: 30px;
}

.rs-popover.scatter{
  --content-bg: var(--light-blue);
  background-color: var(--light-blue);
}

.drop-down__item {
  padding: 5px 28px 5px 16px;
  text-align: left;
  width:200px
}

.drop-down__separator{
  height: 1px;
  margin: 3px 5px;
  background: var(--dark);
}

.row {
  padding: 6px;
}
.row.alternate {
  background-color: var(--secondary-bg);
}
.row:hover {
  background-color: var(--darkgray) !important;
}

.rs-btn-subtle {
  -webkit-transition: none;
}
