.valueBarListWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  min-height: 200px;
  position: relative;
}

.valueBarListBlockTitle {
  margin-bottom: 13px;
}

.valueBarListBlockBars {
  width: 5%;
  display: flex;
  flex-direction: column;
}

.valueBarListBar {
  position: relative;
  margin-bottom: 3px;
}

.valueBarListBarConnect {
  position: absolute;
  top: 50%;
  right: -5px;
}


.valueBarListBlockValues {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-width: 80%;
}

.valueBarListValuesList {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
