@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700');
/*@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;700&display=swap');*/
/*@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');*/
@import '../../../node_modules/react-virtualized/styles.css';
@import '../../../node_modules/react-datepicker/dist/react-datepicker.css';
@import url('__variables.css');
@import url('./_typography.css');
@import url('./_slider.css');

@import url('./_widgets.css');
@import url('./_calendar.css');
@import url('./_buttons.css');
@import url('./_form.css');
@import url('./_common.css');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Nunito;
  /*font-family: Lato;*/
}

html {
  height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
  background: var(--bg-color) !important;
  -webkit-transition: background-color 1s ease-out, color 1s ease-out;
  -moz-transition: background-color 1s ease-out, color 1s ease-out;
  -o-transition: background-color 1s ease-out, color 1s ease-out;
  transition: background-color 1s ease-out, color 1s ease-out;
}

a, a:hover, a:active {
  text-decoration-line: none !important;
  text-decoration: none !important;
  /*color: unset;*/
  /*color: white;*/
}

#root {
  height: 100vh;
}

.App-container {
  display: flex;
  flex-direction: row-reverse;
  overflow: hidden;
  height: 100%;
  min-height: 100vh;
}

.App-content {
  min-width: 375px;
  display: flex;
  flex: 1;
  /*flex-grow: 1;*/
  flex-direction: column;
  overflow-x: hidden;
  padding: 7px 30px 24px;
  position: relative;
}



/** OTHER **/
.echart-wrapper {
  height: 100%;
  width: 100%;
  flex-grow: 1;
}

.noScroll {
  pointer-events: none;
}


.separate-page-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin: -8px -50px;
  overflow: auto;
}

.separate-page-content__item {
  display: flex;
  flex-direction: column;
  padding: 0 50px;
}

.separate-page-content__item--top {
  padding-top: 8px;
  background: var(--selected-bg);
  flex: 0 0 auto;
  padding-bottom: 38px;
}

.separate-page-content__item--bottom {
  padding-top: 40px;
  padding-bottom: 1px;
  flex-grow: 1;
}



.input {
  border-radius: 8px;
  border: var(--input-border);
  background-color: var(--bg-dark);
  padding: 0.4rem 0.5rem;
  color: white;
  margin: 0.6rem 0rem 0rem 0rem;
  width: 100%;
}

.submit {
  height: 2rem;
  border-radius: 8px;
  font-size: 0.8rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  min-width: 4rem;
}
.submit:hover {
  cursor:pointer;
}


button {
  background-color: var(--dark);
  border: 0px;
  border-radius: var(--btn-border-radius);
  padding: 9px 6px;
  /*padding: var(--btn-paddings);*/
  color: var(--btn-text-color);
  /*font-size: 1rem;*/
}

button:hover {
  background-color: var(--dark-blue);
}
.button, button.button {
  border-radius: var(--btn-border-radius);
}

.react-datepicker-ignore-onclickoutside {
  border-radius: 8px;
  border: solid 1px #373b4d;
  border: var(--input-border);
  /*background-color: #171b24;*/
  background-color: var(--bg-dark);
  padding: 0.4rem 0.5rem;
  color: white;
  width: 100%;
}

.Toastify__toast--warning {
  background: orange !important
}


.StripeElement {
  height: 40px;
  padding: 10px 12px;
  width: 100%;
  color: #32325d;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 4px;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

amplify-authenticator {
  --container-height: 100%;
}
