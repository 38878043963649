.formWrapper {
  padding: 32px 0 40px;
  max-width: 572px;
  width: 100%;
}

.formContent {
  display: flex;
  padding-bottom: 23px;
}

.pictureAction {
  text-align: center;
  margin-top: 10px;
  width: 100%;
}

.avatarWrapper {
  padding-top: 10px;
  width: 180px;
}

.avatar {
  width: 180px;
  height: 180px;
  border: 1px solid var(--border-color);
  border-radius: 50%;
}

.fieldsWrapper {
  padding-left: 46px;
  flex-grow: 1;
}

.fieldWrapper {
  margin-bottom: 23px;
}

.formFooter {
  padding-top: 31px;
  border-top: solid 1px var(--secondary-bg);
  text-align: right;
}

.formButton {
  margin-left: 8px;
}
