.periodToggleContainer {
  text-align: left;
  margin-top:-20px;
  margin-bottom: 15px;
}
.toggleBox, .toggleBoxRev {
  display: flex;
  width: 80px;
  height: 30px;
  margin-top: 10px ;
  color:white;
  border-radius: 5px;
  background-color: var(--light-dark)

}
.toggleBoxRev {
  flex-flow: row-reverse;
}
.toggleLabel {
  flex: 1;
  color: var(--text-color);
  padding: 8px;
  font-size: 14px;
}
.toggleButton {
  border-radius: 5px;
  background-color: var(--selected);
  margin: 2px;
  padding: 0;
  width: 30px;
  height: 26px;
  font-size: 12px;
  font-weight: bold;
}
