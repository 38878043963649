.chartLegendContainer {
  display: flex;
  flex: 1;
}
.legendItem {
  display: flex;
  flex-direction: row;
  margin-right: 20px;
}
.ytmSymbol, .ytcSymbol {
  display: flex;
  width: 14px;
  height: 14px;
  background-color: #6677cc;
  margin-right: 5px;
  border-radius: 50%;
  box-sizing: border-box;
}
.ytcSymbol {
  background-color: #c2b261;
}
.labelItem {
  display: flex;
  font-size: 14px;
}
