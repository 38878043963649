
.assetsDashboardGrid {
  display: flex;
  flex-direction: column;
}

.dashboardInfo {
  display: flex;
  max-width: 1024px;
  max-height: 100px;
  flex-shrink: 0;
  margin:28px 0;
}

.tableColumn:nth-last-child(-n+3) {
  justify-content: flex-end;
  padding-right: 10px;
}
.headerTitleContaineer {
  height: 60px;
  font-size: 30px;
}

.headerContainer:nth-last-child(-n+3) {

  div {
    justify-content: flex-end;
    text-align: right;
    width: 100%;
  }
}

.dashboardAssetsMenu{
  margin-bottom: 10px;
}

.contaienr {
  margin-top: 15px;
}

.dashboardInfoItem {
  --border-color: rgba(102, 119, 204, 0.2);
  display: flex;
  padding-right: 12px;
  margin-right: 6px;

  &:last-child {
    border: none;
    padding-right: 0;
    margin-right: 0;
  }

  &.dashboardInfoItemStart {
    justify-content: flex-start;
  }
}

.defaultContainer {
  display: flex;
  height: 450px;
}

.singleContainer {
  width: 100%;
}

.equityContainer {
  display: flex;
  height: auto;
}
.currencyFilter {
  display: block;
  color: var(--text-white);
  margin-right: 10px;
  margin-left: 4px;
}
.currencyFilterLabel {
  padding: 0 8px 0 30px;
  line-height: 24px;
}
