.header > * {
  margin-left: 8px;
  align-self: center;
}

.searchInp {
  width: 140px;
  height: 100%;
  font-size: 12px;
  align-self: center;
}

.tableWrapper {
  margin-top: 24px;
  flex-grow: 1;
}

.filterBtn {
  &:before {
    content: '';
    background: url('../../../../static/images/instrument-filter.svg');
    height: 20px;
    width: 16px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px;
  }
  white-space: nowrap;
  text-align: center;
  height: 30px;
  border-radius: 4px;
  box-shadow: 0 0 1px 2px #d3d8eb5e;
  border: none;
  color: rgba(211, 216, 235, 0.75);
  display: inline-block;
  vertical-align: middle;
  font: 400 12px/1.67em var(--font-family);
  padding: 6px 14px;
  cursor: pointer;
  align-self: center;
}

.filterGrid {
  display: grid;
  grid-template-columns: 1fr 3fr 0.8fr repeat(3, 1fr) 86px;
  grid-auto-columns: 1fr;
  grid-gap: 16px 8px;
  align-items: baseline;
  margin-bottom: 32px;
}

.resetBtn {
  align-self: center;
  margin-top: 18px;
  height: 30px;
  background-color: transparent;
  box-shadow: 0 0 1px 2px #d3d8eb5e;
  border: none;
  color: rgba(211, 216, 235, 0.75);
  padding: 6px;
}
