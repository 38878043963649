:root {
  --gutter: 1rem;
  --logo-size: 2.5rem;
  --icon-size: 2rem;
  --open-width: 240px;
}

.active .menuLink{
  background-color: var(--primary-color);
}

.menuLink:hover {
  background-color: var(--dark-two);
}

/*.menuLink:hover div {*/
/*  font-weight: bold;*/
/*}*/

.menuLink, .active .menuLink a, .active .menuLink a:active, .active .menuLink a:hover{
  color: white !important;
}

.disabled {
  cursor: not-allowed;
}


.surround {
  width: 240px;
  height: 100%;
  background-image: linear-gradient(var(--light-dark), var(--light-dark));
  display: flex;
  flex-direction: column;
}
.surround.closed {
  width: 60px;
}


.fullWidthButton {
  flex: 1;
  padding: 6px 10px !important;
  margin: 0 0.8rem;
  display: block;
}

.avatarWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--gutter);
  padding-left: 0.8rem;
}

.avatarContainer {
  margin-right: var(--gutter);
}


.brandName {
  margin-bottom: 6px;
  margin-left: var(--gutter);
  font-weight: bold;
  font-size: 1rem;
}

.icon {
  height: var(--icon-size);
  width: var(--icon-size);

}
.iconSurround {
  width: 2rem;
  display: flex;
  justify-content: center;
  margin: 0 1rem 0 0.5rem;
}

.collapseIconSurround {
  margin-right: 0.2rem;
  transform: rotate(0deg);
  transition: transform 0.2s linear;
}
.collapseIconSurround.collapsed {
  transform: rotate(180deg);
}

.label {
  display: block;
  /*font-weight: bold;*/
  font-size: 0.9rem;
  margin-left: 1rem;
  margin-bottom: 0.2rem;
  color: var(--text-disabled-color);
}

.logo {
  background: url("../../static/images/logo.svg") center no-repeat;
  height: var(--logo-size);
  width: var(--logo-size);

  /*margin-right: var(--gutter);*/
}
.logo.open {
  background-size: var(--logo-size);
}

.whiteLabelLogo {
  width: calc(var(--open-width) - 2 * var(--gutter));
  height: 60px;
  object-fit: contain;
  margin-bottom: 0.5rem;
}
.whiteLabelLogo.closed {
  width: 3.5rem;
  margin-left: calc((var(--gutter) / -1));
}


.poweredBySurround {
  display: flex;
  align-items: center;
}
.poweredByText {
  padding-top: 0.5rem;
}

.hasSubMenu:hover::after {
  position: absolute;
  right: 0;
  content: '';
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;

  border-left:4px solid white;
  /*background-color: red;*/
}

.hasSubMenu::after {
  position: absolute;
  right: 0;
  content: '';
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;

  border-left:4px solid var(--text-disabled-color);
  /*background-color: red;*/
}


.infoButtonSurround {
  display: flex;
  margin-left: var(--gutter);
  margin-top: 10px;
  cursor: pointer;
}
.infoButtonSurround.closed {
  margin-left: 1.2rem
}

.infoButton {
  margin-right: 20px;
}
.infoText {
  font-size: 0.9rem;
}

.menuContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  overflow: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.menuContent::-webkit-scrollbar {
  display: none;
}

.menuLink {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 2.2rem;
  /*font-weight: 700;*/
  font-size: 0.9rem;
}

.menuSection {
  margin-top: 0.5rem;
  padding-top: 0.5rem;
  border-top: 1px solid var(--text-disabled-color);
}
.noBorder{
  border:none
}

.menuToggleSurround {
  width: 100%;
  height: 40px;
  background-color: var(--bg-dark);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 1.3rem;
  cursor: pointer;
}
.menuToggleSurround:hover {
  background-color: var(--bg-darkest);
}

.menuToggle.open {
  transform: rotate(180deg);
}

.portfolioActionIcons {
  display: flex;
  align-items: center;
  /*padding-right: 0.5rem;*/
}
.portfolioActionIcons.closed {
  flex-direction: column;
  width: 100%;
  margin-left: 8px;
  margin-bottom: 10px;
}

.portfolioLabelSurround {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /*margin-bottom: 4px;*/
}
.closed label.label {
  display: none;
}

.top {
  padding: var(--gutter);
}
.top.flexEnd {
  display: flex;
  align-items: flex-end;
}

.ul {
  margin-bottom: 0;
  list-style-type: none;
}

.userMenu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 0.9rem;
}


.subMenuLink {
  font-size: 125%;
  margin-top: 0.2rem;
}
.subMenuLink:hover {
  font-weight: bold;
}

.searchBar {
  background: var(--bg-darkest) !important;
}

.systemDate {
  padding-left: 0.8rem;
  /*margin-top: -0.3rem;*/
  /*margin-bottom: 0.1rem;*/
  color: var(--blue-grey);
}
.systemDate.closed {
  width: 1px;
  overflow: hidden;
}

/*@media screen and (min-height: 860px) {*/
/*  .menuSection {*/
/*    margin-top: 80px;*/
/*  }*/
/*}*/
