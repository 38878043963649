
.assetsDashboardGrid {
  display: flex;
  flex-direction: column;

}

.dashboardInfo {
  display: flex;
  max-width: 1024px;
  max-height: 56px;
  flex-shrink: 0;
  margin:28px 0;
}

.tableColumn:nth-last-child(-n+3) {
  justify-content: flex-end;
  padding-right: 10px;
}

.headerContainer:nth-last-child(-n+3) {

  div {
    justify-content: flex-end;
    text-align: right;
    width: 100%;
  }
}

.dashboardInfoItem {
  --border-color: rgba(102, 119, 204, 0.2);

  display: flex;
  padding-right: 24px;
  margin-right: 14px;
  border-right: 1px solid var(--border-color);

  &:last-child {
    border: none;
    padding-right: 0;
    margin-right: 0;
  }
}

.defaultContainer {
  margin-top: 25px;
  display: flex;
  min-height: 400px;
}

.singleContainer {
  width: 100%;
}

.topPositionsChartContainer {
  margin-right: 30px;
}
.profitChartContainer {
  height: 400px;
}
.equityContainer {
  display: flex;
  height: auto;
}




/* Cash Top Positions Chart */
.CashPositionsChartContainer {
  margin-right: 20px;
  margin-left: 20px;
  box-sizing: border-box;
}
.filterLabel {
  background: url('../../../../../../../../static/images/preview.svg') left center no-repeat;
  background-size: 20px;
  padding: 0 0 0 30px;
  line-height: 24px;
}
.chartLegendContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}
.legendShort {
  text-align: center;
  width: 33%;
  font-size: 13px;
  font-weight: 500;
  color: var(--light-blue);
}
.legendLong {
  text-align: center;
  color: var(--text-color);
  width: 33%;
}
.legendNetPos {
  text-align: center;
  width: 33%;
  color: #41d9a0;
}
.legendNetNeg {
  text-align: center;

  width: 33%;
  color: var(--alert);
}
.barLabel {
  padding: 4px 8px;
}

.positiveBarItem {
  width: 50%;
  float: right;
  margin-right: 1px;
  border-left: 1px solid #a9b2d1;
  box-sizing: border-box;
}
.positiveBarItem .bar {
  background-color: #41d9a0;
  height: 25px;
  box-sizing: border-box;
}

.negativeBarItem {
  width: 50%;
  text-align: right;
  color: var(--light-blue);
  float: left;
  border-right: 1px solid #a9b2d1;
  box-sizing: border-box;
}
.negativeBarItem .bar {
  background-color: #ff6972;
  height: 25px;
  float: right;
  box-sizing: border-box;
}
.barSpacer {
  height: 20px;
  width: 50%;
  border-right: 1px solid #a9b2d1;
  clear: both;
  box-sizing: border-box;
}

