.ChartContainer {
  /*background-color: red;*/
  font-size: 13px;
  font-family: Arial, Helvetica, sans-serif;
  width: 97%;
  height: 400px;
  padding: 10px;
}
.yAxisCanvas {
  width: 100%;
  height: 246px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
.AxisLine {
  width: 100%;
  height: 65px;
  box-sizing: border-box;
  border-bottom: 1px solid #6677cc;
  opacity: 0.5;
  position: relative;
}
.yAxisLabel {
  position: absolute;
  bottom: 0;
  right: -20px;
  text-align: left;
  color: #d7e0ff;
  font-size: 12px;
}
.BarsCanvas {
  display: flex;
  height: 260px;
  margin-top: -228px;
  position: relative;
}
.BandsCanvas {
  display: flex;
  height: 24px;
}
.ArrowsCanvas{
  height: 70px;
  width: 100%;
}
.xAxisCanvas{
  display: flex;
  height: 20px;
}
.xAxisLabelEnd {
  float: right;
}
.BenchmarkVsPortfolioCanvas {
  height: 45px;
  margin-top: -20px;
}
.BenchmarkVsPortfolioSlots {
  display: flex;
  height: 55px;
  margin-top: -4px;
}
.BenchmarkVsPortfolioSegment {
  width: 100%;
  text-align: center;
  color: #d7e0ff;
  box-sizing: border-box;
}
.BenchmarkVsPortfolioTag {
  width: 16px;
  height: 16px;
  padding: 2px;
  color: #1c202e;
  font-size: 12px;
  font-weight: bold;
  margin: 0 auto 2px auto;
}
.BenchmarkTag {
  background-color: #138eb0;
}
.PortfolioTag {
  background-color: var(--esma-band4-color);
}
.BarColumn {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  margin: 0;
  width: 15%;
  height: 100%;
  box-sizing: border-box;
}
.BarValueBox {
  width: 60px;
  box-sizing: border-box;
}
.BarValue {
  color: #fff;
  border-radius: 2px;
  text-align: center;
  width: 100%;
  min-height: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
}
.BarLabel {
  width: 100%;
  height: 32px;
  color: #fff;
  text-align: center;
  font-size: 11px;
  padding: 2px;
  border: 1px solid var(--esma-band1-color);
  border-radius: 4px;
  box-sizing: border-box;
}
.BandStrip {
  color: #fff;
  background-color: var(--esma-band1-color);
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  margin: 0 1px;
  padding: 2px
}
.LabelStripX{
  width: 100%;
  padding-top: 2px;
  text-align: left;
  font-size: 12px;
  color: #d7e0ff;
}
.Esma1Bg {
  background-color: var(--esma-band1-color);
}
.Esma2Bg {
  background-color: var(--esma-band2-color);
}
.Esma3Bg {
  background-color: var(--esma-band3-color);
}
.Esma4Bg {
  background-color: var(--esma-band4-color);
}
.Esma5Bg {
  background-color: var(--esma-band5-color);
}
.Esma6Bg {
  background-color: var(--esma-band6-color);
}
.Esma7Bg {
  background-color: var(--esma-band7-color);
}
.Esma2Bd {
  border-color: var(--esma-band2-color);
}
.Esma3Bd {
  border-color: var(--esma-band3-color);
}
.Esma4Bd {
  border-color: var(--esma-band4-color);
}
.Esma5Bd {
  border-color: var(--esma-band5-color);
}
.Esma6Bd {
  border-color: var(--esma-band6-color);
}
.Esma7Bd {
  border-color: var(--esma-band7-color);
}


/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

/* Tooltip */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  height: 48px;
  line-height: 16px;
  background-color: black;
  color: #fff;
  text-align: left;
  padding: 8px;
  border-radius: 6px;
  top: 35%;
  left: 65%;
  position: absolute;
  z-index: 1;
}
.tooltip:hover .tooltiptext {
  visibility: visible;
}
