/*--- For all react tables ---*/
.ReactVirtualized__Table {
  --header-text-color: var(--darkgray);
  --header-text-font: var(--small-text);
  --header-bg: trasparent;
  --bg-even-row: trasparent;
  --bg-odd-row: var(--secondary-bg);
  --sort-color: var(--text-color);

  .table-row {
    line-height: 24px;
    //cursor: pointer;

    &.hoverable:not(.ReactVirtualized__Table__headerRow) {
      cursor: pointer;
    }

    &--clicked {
      //cursor: pointer;
    }
    transition: background-color 0.1s ease;
  }

  .table-row:focus{
    background-color: var(--dusk) !important;
    color: var(--text-white) !important;
  }

  .table-row-wrapper {
    &--error {
      --bg-odd-row: rgba(249, 112, 99, 0.12);
      --bg-even-row: rgba(249, 112, 99, 0.2);
    }

    &--warning {
      --bg-odd-row: rgba(148, 108, 61, 0.5);
      --bg-even-row: rgba(148, 108, 61, 0.4);
    }

    &:nth-child(even) .ReactVirtualized__Table__row.table-row {
      background: var(--bg-even-row);
    }

    &:nth-child(odd) .ReactVirtualized__Table__row.table-row {
      background: var(--bg-odd-row);
    }
    .table-column {
      padding: 4px 0 5px;
    }
  }

  .table-row--clicked {
    //cursor: pointer;
  }

  .ReactVirtualized__Grid {

  }

  .table-header {
    background-color: var(--header-bg);

    &__column{
      display: flex;
      font: var(--header-text-font);
      text-transform: none;
      color: var(--header-text-color);
    }
  }

  .table-column {
    display: flex;
    padding: 12px 0;
    font: var(--small-text);
  }

  .table-column__text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .sort-direction-column {
    display: inline-block;
  }

  .sort-direction-column::after {
    content: '';
    display: inline-block;
    height: 4px;
    width: 4px;
    margin-left: 5px;
    opacity: 0.6;
  }

  .sort-direction-column--acs::after {
    transform: rotate(-135deg) translateY(50%);
    border-bottom: 2px solid var(--sort-color);
    border-right: 2px solid var(--sort-color);
  }

  .sort-direction-column--desc::after {
    transform: rotate(-135deg) translateY(50%);
    border-top: 2px solid var(--sort-color);
    border-left: 2px solid var(--sort-color);
  }


  /* list of special header columns */

  .table-header__column--action,
  .table-header__column--actions{
    background: url('../../../static/images/settings.svg') no-repeat center center;
    opacity: 0.6;
  }
}
