.content {
}

.terms {
  margin: 2rem 0;
  background-color: var(--bg-dark);
  padding: 0.5rem;
  border-radius: var(--btn-border-radius);
}

.content p {
  display: block;
  max-width: 90%;
  padding-left: 10px;
}

.content li {
  padding: 10px 20px 0;
}
.content ol {
  padding: 10px 20px 0;
}

.rsBtn {
  overflow: visible !important;
  /*background-color: red !important;*/
  /*height: 80px;*/
}

@media only screen and (min-width: 800px) {
  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    padding: 3rem 0;
  }

  .terms {
    /*flex: 1;*/
    overflow-y: scroll;
  }
}
