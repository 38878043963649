.container{
  margin-top:40px;
  h2{
    font-size: 28px;
  }
}

.headerRow{
  display:flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  align-items: center;
}

.circlesRow{
  display:flex;
  flex:1;
  flex-direction: row;
  align-content: center;
  justify-content: flex-end;
  align-items: center;
  margin-top:30px
}

.circleContainer{
  display: flex;
  flex-direction: row;
  margin-left: 10px;
  align-items: center;
}


.circle{
  height:15px;
  width:15px;
  border-radius: 7.5px;
  text-align: center;
  align-items: center;
  align-self: center;
  margin:auto;
}




.circleSm{
  height:10px;
  width:10px;
  border-radius: 5px;
}
