.notificationWrapper {
  --notification-bg: var(--dark);
  --notification-shadow: var(--dark);
  --notification-color: var(--text-white);

  position: absolute;
  top: 24px;
  left: 50%;
  transform: translateX(-50%);

  padding: 8px 8px 8px 48px;
  border-radius: 4px;
  box-shadow: 0 0 0 1px var(--notification-shadow);
  background-color: var(--notification-bg);
  background-repeat: no-repeat;
  background-position: 14px center;
  color: var(--notification-color);
  z-index:9999999;

  &.success {
    --notification-shadow: var(--confirm);
    background-image: url('../../../static/images/notification/success.svg');
  }

  &.error {
    --notification-shadow: var(--alert);
    background-image: url('../../../static/images/notification/error.svg');
  }
}

.text {
  font: var(--small-title)
}

.title {
  font: var(--medium-title)
}
