.wrapper {
  border-radius: 6px;
  padding: 15px;
  margin:5px;
  background: var(--light-dark);
  filter: drop-shadow(0 0 5px var(--bg-darkest));
}

.blotterIcon {
  margin:0 auto;
}

.bg {
}

.cardSmall {
  width: calc(20% - 10px);


  @media (max-width: 1360px) {
    width: calc(25% - 10px);
  }
  @media (max-width: 1160px) {
    width: calc(33% - 10px);
  }
  @media (max-width: 768px) {
    width: calc(50% - 10px);
  }
  @media (max-width: 414px) {
    width:100%;
  }
  @media (min-width:1650px) {
    width: calc(16.66% - 10px);
  }

}

.cardBig {
  width: calc(25% - 10px);

  @media (max-width: 1160px) {
    width: calc(33% - 10px);
  }
  @media (min-width:1650px) {
    width: calc(20% - 10px);
  }
  @media (max-width: 768px) {
    width: calc(50% - 10px);
  }
  @media (max-width: 414px) {
    width:100%;
  }
}

.label{
  font-size:10px;
}

.dropdownTitle{
  font-weight: bold;
  padding: 5px 16px 5px 16px;
  margin-top:10px;
  margin-bottom:10px;
  text-align: left;
}

.dropdownItem{
  padding: 5px 16px 5px 16px;
  margin-top:10px;
  text-align: left;
  &:hover {
    background-color: var(--secondary-bg);
  }
}

.workGroupItemContainer{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.workGroupItemInfoBlock{
  display: flex;
  flex: 1;
  flexDirection: row;
  justifyContent: center
}

.workGroupItemDropDown{
 display: flex;
  flex-direction: row;
  align-items: center
}

.dropdownContainer{
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  min-width:250px;
  margin:5px;
}

.idSpan{
  font-size:10px;
  color:white;
}

.workgroupSpan{
  margin-left: 10px;
  font-size:10px;
}
