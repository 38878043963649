.marketBlockTitle {
  margin-bottom: 24px;
}

.incomeForm {
  margin-bottom: 24px;
}

.incomeTable {
  margin-bottom: 2rem;
  height: 100%;
  min-height: 150px;
}

.historyTitle{
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}
