.input {
  /*width: 400px;*/
  flex: 1;
  color: var(--text-color);
  background-color: transparent;
  border: none;
  padding: 0.5rem;
}

.inputSurround {
  width: 100%;
  background-color: var(--bg-dark);
  height: 2rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--btn-border-radius);
}
