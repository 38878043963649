.modalContainer{
:global(.rs-modal-content){
  background-color: var(--light-dark);
}
}

.modalTitle{
    font-size:24px;
}

:global(.hover-table-surround .rs-table-row){
  cursor: pointer;
}

