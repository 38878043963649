.wrapper {
    text-align: center;
}
  
.formWrapper {
    margin-top: 40px;
    text-align: left;
}

.formWrapper h4{
    color: var(--text-color);
}

.firstRow {
    display: grid;
    grid-template-columns: 6fr 1.45fr;
    grid-gap: 10px;
    width: 100%;
    text-align: left;
    margin-bottom: 30px
}

.labelContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 15px
}

.labelTable {
    display: flex;
    margin-top: 15px;
    align-items: center;
  
    .labelContainer {
       width: 50%;
    }
}

.firstRowClient {
    grid-template-columns: 3fr 3fr 1.48fr;
}
  
.row {
    display: grid;
    justify-content: center;
}
  
.indexRow {
    display: grid;
    grid-gap: 10px;
    grid-template-columns:1fr;
    justify-content: left;
    align-items: center;
    margin: 15px 0 30px;
}

.typeList {
    list-style: none;
    display: flex;
    margin: 40px 5px;
    justify-content: center;
}


.typeItem {
    --border-color: transparent;
  
    width: 209px;
    margin: 0 15px;
    text-align: center;
    background: var(--selected-bg);
    border: 2px solid var(--border-color);
    border-radius: 4px;
    background-repeat: no-repeat;
    background-size: 55%;
    background-position: center 40px;
  
  
    &:hover,
    &.active {
      --border-color: var(--primary-color);
    }
  
    &.personal {
      background-image: url('/static/images/personal-portfolio.svg');
    }
  
    &.model {
      background-image: url('/static/images/model-portfolio.svg');
    }
  
    &.client {
      background-image: url('/static/images/client-portfolio.svg');
      background-position: center 25px;
    }
}
  
.disabled {
    opacity: 0.4;
    &:hover {
      --border-color: transparent;
    }
}
  
.itemBtn {
    padding: 156px 15px 32px;
    color: var(--text-color);
}
  
.typeName {
    font: var(--medium-text);
}
  
.typeDescription {
    margin-top: 16px;
    text-align: right
}

.createBtn {
    width: 120px;
}

.brokerLabelsRow {
    display: grid;
    grid-gap: 10px;
    grid-template-columns:1fr auto 1fr;
    justify-content: center;
    align-items: center;  
    margin-bottom: 15px;
}