.formGrid {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: baseline;
}


.formRow {
  display: flex;
  width: 100%;
  min-height: 78px;
  align-items: flex-start;

  &:empty {
    display: none;
  }

  &>*:not(:last-child) {
    margin-right: 10px;
  }
}

.instrumentField {
  width: 100%;
  min-width: 100px;
}

.underlyingField {
  width: 100%;
  min-width: 70px;
}

.custodianField {
  min-width: 120px;
  width: 100%;
  position: relative;
}

.custodianSmall {
  width: 250px;
}

.comboPseudoArrow {
  width: 22px;
  height: 22px;
  position: absolute;
  right: 0;
  background-image: url('../../../../../../static/images/arrow-right.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  transform: rotate(90deg);
}

.expiryField {
  min-width: 90px;
  width: 90px;
}



.smallField {
  min-width: 90px;
  width: 90px;
}

.tinyField {
  min-width: 60px;
  width: 60px;
}

.yearField {
  min-width: 70px;
  width: 70px;
}

.strikeField {
  min-width: 200px;
  width: 100%;
}

.optionTickerField {
  min-width: 120px;
  width: 100%;
}

.validateButtonContainer {
  min-height: 78px;
  display: flex;
  justify-content: center;
}

.addBtn {
  width: 60px;
}


.assetField {
  min-width: 200px;
  width: 100%;
}

.commissionField {
  min-width: 110px;
}

.mediumField {
  min-width: 140px;
}

.fxWrapper{
  flex:0!important;
}

.minField{
  min-width: 130px;
  flex:1;
}
.tradeTime{
  width: 125px;
}

.typeField {
  width: 170px;
  min-width: 170px;
}

.noteField {
  width: 100%;
  //min-width: 100%;
}

.settlementField {
  min-width: 120px;
}

.currencyField {
  min-width: 120px;
}

.operationField {
  min-width: 120px;
}

.closePrice {
  color: var(--darkgray);
  font-size: 0.8rem;
}


.tradeToggle {
  display: flex;
  border-radius: 4px;
  background: rgba(59, 71, 112, 0.5);

  .tradeToggleBtn {
    flex-grow: 1;
    min-width: auto;
  }
}

.tradeToggleBtn {
  --btn-bg-color: transparent;
  background-color: var(--btn-bg-color);
  border: 0;

  &.active {
    --btn-bg-color: var(--dusk);
  }

}

.formBtn {
  line-height: 32px;
  padding: 0;
  border: 0;
  &.smallLineHeight {
    line-height: 16px;
    font-size: 0.6rem;
  }
}

.amountWrapper {
  margin-right: 18px;
}

.formFooter {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
}

.warningText {
  color: var(--text-warning-color)
}

.datePicker{
  min-width: 130px;
  max-width: 130px;
  background-color: #23283D;
  margin-right:50px;
  border-radius: 4px;
  height:32px;
}

