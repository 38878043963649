.listContainer {
  border: solid 1px var(--dark-two);
}

.listContainer:focus {
  outline: -webkit-focus-ring-color auto 5px;
}

.itemContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding:10px;
  min-height: 42px;

  &:nth-child(odd) {
    background: var(--dark-two);
  }
}

.itemContainer h4 {
  margin: 0 !important;
}

.itemActions {
  display: flex;
  align-items:center;
}

.buttonStyle {
  margin-left: 10px;
  cursor: pointer;
}

.itemOption {
  width: 16rem;
}

.itemName {
  width: 18rem;
}