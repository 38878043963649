.label {
  --checkbox-size: 14px;
  --checkbox-bg: rgba(255, 255, 255, 0.2);

  display: flex;
  position: relative;
  cursor: pointer;
  line-height: var(--checkbox-size);

  .checkboxView {
    margin-right: 6px;
    flex-shrink: 0;
    width: var(--checkbox-size);
    height: var(--checkbox-size);
    opacity: 1;
    border-radius: 3px;
    background-color: var(--checkbox-bg);
    position: relative;

    &::before {
      content: "";
      display: block;
      width: calc(var(--checkbox-size) - 2px);
      height: calc(var(--checkbox-size) - 2px);
      background: url("./check-mark.svg") no-repeat center center;
      position: absolute;
      top: 1px;
      left: 1px;
      opacity: 0;
      transform: translateZ(0) scale(.5);
      transition: all 200ms ease-out;
    }
  }
}

/* Checked */
.checkbox {
  position: absolute;
  opacity: 0;
  background: transparent;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.checkbox:checked + .checkboxView {
  &::before {
    opacity: 1;
    transform: scale(1);
  }
}

/* Disabled */
.checkbox:disabled + .checkboxView {
  opacity: 0.5;
}

.checkbox:checked:disabled + .checkboxView {
  &::before {
    opacity: 1;
    transform: scale(1);
    background-image: url("./check-mark.svg");
  }
}

.checkbox:focus + .checkboxView {
  outline: none;
}
