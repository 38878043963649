a {
  color: white;
}
a:visited, a:hover, a:focus {
  color: white;
}


.rs-picker-menu{
  background-color: var(--secondary-bg);
}

.rs-picker-default .rs-btn, .rs-picker-input .rs-btn, .rs-picker-default .rs-picker-toggle, .rs-picker-input .rs-picker-toggle{
  background-color: var(--secondary-bg) !important;
}

.rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner::after, .rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner::after{
  border-color:white;
}

.rs-popover::-webkit-scrollbar {
  display: none;  /* Safari and Chrome */
}
.rs-popover {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}


.rs-btn-primary {
  background-color: var(--selected);
}

.rs-table-cell-header {
  padding: 0 !important;
}
.rs-table-cell-content {
  padding: 6px 5px 5px !important;
}
.rs-table-header-row-wrapper {
  z-index: 0;
}


.rs-picker-select-menu-item.rs-picker-select-menu-item-active,
.rs-picker-select-menu-item.rs-picker-select-menu-item-active:hover,
.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value,
.rs-picker-toolbar-option
{
  color: var(--text-light-color);
}


.rs-popover {
  //overflow: visible;
  padding: 4px;
  //overflow-x: visible;
}

.rs-popover[class*='placement-right'] > .rs-popover-arrow::after {
  border-right-color: var(--dark-two);
}

@base-color: #2E3D71;