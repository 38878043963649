.datePicker{
  min-width:200px;

  :global(.k-picker-wrap), :global(.k-dateinput-wrap){
    background-color: transparent;
    color:white;
  }
  :global(.k-select){
    color:white;
    background-color: transparent;
  }
  :global(.k-select):hover{
    background-color: transparent;
  }
}

.hideWeekends  {
  :global(.k-weekend){
    color: var(--text-disabled-color);
    cursor: not-allowed;
    pointer-events: none;
  }
}


