
.custodiansFilterContainer {
  position: absolute;
  right: -50px;
  top: -40px;
  height: 28px;
}

.custodiansLabel {
  display: flex;
  justify-content: flex-end;
  //background-color: var(--light-dark);
  padding: 0 20px;
}
.ddLabel {
  padding: 5px;
  cursor: pointer;
  display: inline-flex;
}
.custFilterIcon {
  display: inline-flex;
}
.custLabel {
  color: var(--text-white);
}

.comboPseudoArrow {
  width: 18px;
  height: 18px;
  margin: -3px 5px 0 5px;
  background-image: url('../../../../../../../../../src/static/images/arrow-right.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  transform: rotate(90deg);
}

.checklistVisible, .checklistHidden {
  width: 300px;
  margin-top: 2px;
  overflow: auto;
  visibility: visible;
  position: relative;
  padding: 5px;
  z-index: 99;
  background-color: var(--dark);
}

.checklistHidden {
  //width: 0;
  //height: 0;
  display: none;
}

.checklistItem {
  padding-top: 5px
}

.buttonsArea {
  margin-top: 12px;
}

.button {
  height: 24px;
  padding: 0 14px;
  font-size: 12px;
  line-height: 8px;
  border-color: var(--btn-border-color);
  outline-color: var(--btn-outline-text-color);
  background-color: var(--btn-bg-color);
  border-radius: var(--btn-border-radius);
  color: var(--text-color);
}


.rightAligned {
  display: flex;
  justify-content: flex-end;
}
