.form {
  display: grid;
  grid-template-columns: 204px 132px 96px min-content;
  grid-column-gap: 8px;
  align-items: flex-start;
}

.noteField {
  grid-column: span 5;
}

.formButton {
  margin-top: 25px;
}
