.buttonSurround {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 1rem;
}

.cancelButton {
  padding: 1rem;
}
.cancelButton:hover {
  cursor: pointer;
}

.checkbox {
  appearance: revert;
  margin-right: 0.5rem;
}

.checkboxSurround {
  height: 40px;
  display: flex;
  align-items: center;
}

.confirm {
  color: var(--confirm);
}

.descriptionSurround {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.description {
  text-align: left;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.descriptionLabel {
  display: inline-block;
  width: 8rem;
}

.disabled {
  background-color: transparent;
  color: grey;
}
.disabled:hover {
  cursor: not-allowed;
  background-color: transparent !important;
}

.indicator {
  --indicator-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  /*position: absolute;*/
  /*right: 25px;*/
  margin-right: 0.5rem;
  top: 2px;
  height: var(--indicator-size);
  width: var(--indicator-size);
  border-radius: 50%;
}

.info {
  margin-top: 2rem;
  margin-bottom: 0.5rem;
}

.inputSurround {
  position: relative;
  margin-bottom: 1rem;
}

.portfolioRow {
  padding: 2px;
  padding-left: 0;
}

.row {
  display: flex;
  align-items: center;
  /*max-height: 4.8rem;*/
  margin-bottom: 12px;
}

.rowLabel {
  width: 8rem;
  font-weight: bold;
}


.statusMessage {
  height: 1rem;
}

.surround {
  text-align: center;
  width: 400px
}

.userListScrollWrap {
  overflow: hidden;
  max-height: 4.8rem;
  transition: height 500ms ease-in-out;
  /*width: 100%;*/
}

.userListSurround {
  width: calc(100% + 30px);
  height: 100%;
  padding-right: 30px;
  box-sizing: content-box;
  /*background-color: var(--bg-dark);*/
  background-color: var(--dark-two);
  overflow-y: scroll;
  border-radius: 0 0 var(--btn-border-radius) var(--btn-border-radius);
}

.userSurround {
  width: 100%;
  background-color: var(--dark-two);
  display: flex;
  padding: 0.2rem 0.5rem;
  height: 1.5rem;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}
.userSurround:hover {
  background-color: var(--active-color);
  cursor: pointer;
}

.warn {
  color: var(--text-warning-color);
}
