.labelContainer {
  margin-bottom: 0;
}

.comboPseudoContainer {
  position: relative;
}

.comboPseudoArrow {
  pointer-events: none;
  width: 22px;
  height: 22px;
  position: absolute;
  right: 5px;
  background-image: url('/static/images/arrow-right.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  transform: rotate(90deg);
}

.custodianRow {
  display: grid;
  grid-gap: 10px;
  grid-template-columns:1fr;
  justify-content: center;
  align-items: center;
  margin: 15px 0 5px;
}




