.wrapper {
  position: relative;
  overflow: hidden;
  height: 72px;
  pointer-events: auto;
}

.modelsList {
  position: absolute;
  left: 0;
  display: inline-flex;
  list-style: none;
  white-space: nowrap;
  transition: all 200ms ease-out;
}

.modelsItem {
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  min-width: 166px;
  min-height: 72px;
  margin-right: 8px;
  background: var(--secondary-bg);
  border-radius: 4px;
  border: 2px solid var(--secondary-bg);
  color: var(--text-color);
  cursor: pointer;

  &:hover {
    border-color: var(--primary-color)
  }
}

.modelsItemButton {
  padding: 12px;
  border: 0;
  background: transparent;
  text-align: left;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

.modelsItemName {
  font-size: var(--text-title-size);
  font-weight: bold;
  color: var(--text-light-color);
}

.modelsItemRisk {
  margin-top: 5px;
  color: var(--text-color);
}

.listNavigationBtn {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  width: 100px;
  padding: 0;
  border: none;
  border-radius: 0;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    z-index: 1;
    width: 32px;
    height: 32px;
    background-image: url('../../../../../../static/images/arrow-right.svg');
    background-color: transparent;
    background-size: 32px auto;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }

  &.previous {
    left: 0;
    background: linear-gradient(90deg, var(--selected-bg) 0%, transparent 100%);

    &::before {
      left: 12px;
      transform: translateY(-50%) rotate(180deg);
    }
  }

  &.next {
    right: 0;
    background: linear-gradient(90deg, transparent 0%, var(--selected-bg) 100%);

    &::before {
      right: 12px;
      transform: translateY(-50%);
    }
  }

  &:focus {
    outline: none;
  }
}
