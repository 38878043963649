.toggle{

}

.toggleContainer{
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width:100px;
  margin-right:5px
}


.chartContainer{

}
