:root {
  --draft-color: var(--warm-grey);
  --active-color: var(--primary-color);
  --hidden-color: var(--chart-2)
}

.wrapper {
  height: 100%;
  padding: 21px 37px 37px 37px;
  background: var(--light-dark);
  border-radius: 6px;
}

.header {
  display: flex;
  padding-bottom: 6px;
}

.infoWrapper {
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.avatarWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  margin-right: 10px;
  flex-shrink: 0;
  border-radius: 50%;
  background: var(--darkgray);
  color: var(--dark);
  font: var(--medium-title);
  font-weight: bold;
}

.footer {
  display: flex;
  justify-content: space-between;
  padding-top: 7px;
}

.createdAt {
  font: var(--small-text);
  flex-grow: 1;
}

.user {
  margin-top: 10px;
}

.status {
  --bg-color: var(--active-color);

  padding: 1px 10px;
  border-radius: 10px;
  color: var(--text-dark);
  background: var(--bg-color);
  text-transform: capitalize;
  font-weight: 500;

  &.draft {
    --bg-color: var(--draft-color);
  }

  &.hidden {
    --bg-color: var(--hidden-color);
  }
}

.unconfirmed {
  position: relative;
  display: flex;
  font: var(--small-text);
  flex-grow: 1;
  margin-left: 14px;
  padding-left: 16px;
  align-items: center;

  &:before {
    content: '';
    position: absolute;
    top: 2px;
    left: 0;
    width: 12px;
    height: 12px;
    background: url('../../../../static/images/attention.svg') no-repeat;
  }
}


.titleIcon {
  width: 24px;
  margin-right: 16px;
}

.portfolioName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: normal;
  display: flex;
  align-items: center;
}

.editWrapper {
  display: flex;
  align-items: center;
}

.editInp {
  --input-font: var(--medium-title);
  --input-bg: none;

  padding: 0 10px 0 0;
  border: none;
}

.saveChangesBtn {
  position: relative;
  height: 12px;
  width: 12px;
  background: url('../../../../static/images/check-mark.svg');

  &:after {
    content: '';
    position: absolute;
    top: -5px;
    right: -5px;
    left: -5px;
    bottom: -5px;
  }
}

.statusBtn {
  --status-color: transparent;

  position: relative;
  padding-left: 33px;

  &::before {
    content: "";
    position: absolute;
    left: 17px;
    top: 50%;
    transform: translateY(-50%);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: var(--status-color);
  }

  &.active {
    &::after {
      content: "";
      position: absolute;
      left: 14px;
      top: 50%;
      transform: translateY(-50%);
      width: 12px;
      height: 12px;
      border-radius: 50%;
      border: 1px solid var(--status-color);
    }
  }

  &.draftBtn {
    --status-color: var(--draft-color);
  }

  &.activeBtn {
    --status-color: var(--active-color);
  }

  &.hiddenBtn {
    --status-color: var(--hidden-color);
  }
}

.performanceContainer {
  width: 100%;
  display: grid;
  grid-template-columns: 420px auto 150px;
}

.pnlPerformance {
  display: flex;
  justify-content: space-between;
  padding-right: 27px;
}

.portfolioStructure {
  align-items: center;
  display: flex;
  border-left: 2px solid #444b62;
  padding-left: 27px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 5px;

}

.portfolioStructureHeader {
  font-size: 18px;
  font-weight: 500;
  color: var(--text-color);
}
.portfolioValueFigure {
  color: var(--text-success-color);
}
.portfolioStructureContent {
  font-size: 20px;
  /* color: #a9b2d1; */

  display: flex;
}

.assetItem {
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-right: 21px;
  }

  & img {
    margin-right: 12px;
  }
}

.more {
  display: none
}

.noData {
  color: var(--darkgray)
}


@media screen and (max-width: 1365px) {

  .more {
    display: inline-block;
    content: "+ MORE";
    margin-left: 17px;
    color: var(--warm-blue);
  }

  .assetItem {
    display: none;

    &:nth-child(-n+3){
      display:flex;
    }
  }
}

.moreDetails {
  padding-top: 5px;
  font-size: 18px;
  font-weight: 300;
  color: var(--text-color);
}
