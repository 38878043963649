.infoBlock {
  display: flex;
  align-items: center;
}

.infoBlockImage {
  width: 56px;
  height: 56px;
}

.infoBlockContent {
  display: flex;
  flex-direction: column;
}


.infoBlockTitle {
  font-size: var(--text-size);
  color: var(--text-color);
  margin-bottom: 2px;
}

.infoBlockValue {
  font: var(--medium-title);
  color: var(--text-light-color);
}

.infoBlockDiff {
  display: inline-block;
  margin-left: 12px;
  border-radius: 2px;
  padding: 2px 6px;
  font: var(--small-text);
  background: var(--selected-bg);
  color: var(--cloudy-blue);
}

