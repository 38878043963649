.rightAligner {
  display: flex;
  justify-content: flex-end;
  margin: 0.5rem 0;
}

.aligner{
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.leftAligner {
  display: flex;
  justify-content: flex-start;
  margin: 0.5rem 0;
}

.summaryContainer {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  margin-right: 10px;
}

.titleBtn {
  margin-left: 8px;
  white-space: nowrap;
}
.titleBtnOffset {
  margin-left: 24px;
}

/*&Wide {*/
/*   --btn-paddings: 6px 28px;*/
/* }*/
.icon {
  margin-right: 8px;
}
