.toggleContainer {
  display: flex;
  padding: 4px;
}

.toggleTrack, .toggleTrackRev {
  display: flex;
  width: 2rem;
  height: 1rem;
  border-radius: 0.45rem;
  border: 1px solid var(--btn-text-color);
  margin: 0 0.5rem;
}

.toggleTrackRev {
  flex-flow: row-reverse;
}

//.leftLabel, .rightLabel {
//  //min-width: 50px;
//}

.toggleKnob {
  width: 0.9rem;
  height: 0.9rem;
  background-color: var(--btn-text-color);
  border-radius: 6px;
  border: none;
  cursor: pointer;
}
