.gridSurround {
    display: grid;
    grid-template-columns: repeat(24, 1fr);
    grid-gap: 0rem 0rem;
}


.input {
    border-radius: 8px;
    border: var(--input-border);
    background-color: var(--bg-dark);
    padding: 0.4rem 0.5rem;
    color: white;
    margin: 0rem;
    width: 100%;
}

.inputLabel {
    position: absolute;
    margin-left: 8px;
    padding: 0px 4px 0px 4px;
    border-radius: 4px;
    background-color: var(--bg-dark);
    font-size: 0.6rem;
    color: #747b96;
}

.inputSurround {
    display: flex;
    grid-column: span 3;
    margin-bottom: 0.5rem;
}
.inputSurround > div {
    width: 100%;
    /*background-color: red;*/
}
.inputSurround input {
    margin-top: 0.6rem;
}
.inputSurround svg {
    margin-top: 0.6rem;
}
.inputSurroundError input{
    border: 2px solid var(--alert);
}

.label {
    color: var(--light);
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    opacity: 0.25;
    border-bottom: 1px solid var(--light);
}


.submit {
    height: 2rem;
    border-radius: 8px;
    font-size: 0.8rem;
    margin-top: 1rem;
    min-width: 4rem;
}

.title {
    padding: 24px 0px;
}