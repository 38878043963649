.wrapper {
  flex-grow: 1;
  width: 100%;
  height: 1px;
}

.widgetsBlock {
  height: 100%;
  display: grid;
  grid-row-gap: 40px;
  grid-column-gap: 20px;
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.widgetItem {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.widgetItemFull {
  grid-column: 1/-1;
}

.widgetsHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;
}

.widgetsHeaderTools {
  display: flex;
  margin: 0 -10px;

  > * {
    margin: 0 10px;
  }
}

.widgetsContent {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
}
