:root {
  --modal-overlay-background: #1c202e;
  --overlay-zindex: 1000;
  --overlay-content-zindex: calc(var(--overlay-zindex) + 1);
}

.overlay {
  position: fixed;
  z-index: var(--overlay-zindex);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--modal-overlay-background);
  overflow: auto;
}

.closeModalBtn {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 23px;
  padding-top: 32px;
  background: url('../../static/images/close.svg') top center no-repeat;

  line-height: 1;
  letter-spacing: 2px;
  font-size: 10px;
  text-align: center;
  color: var(--cloudy-blue);
}


.modalWindow {
  margin: 56px auto;
  max-width: 700px;
}
