.breadcrumbs {
  --breadcrumb-color: var(--darkgray);

  list-style: none;
  line-height: 32px;
  height: 32px;
}

.breadcrumbItem {
  position: relative;
  display: inline-block;
  padding-right: 20px;
  font-weight: bold;
  color: var(--breadcrumb-color);

  &:not(:last-child):after {
    content: '';
    position: absolute;
    right: 4px;
    top: 50%;
    width: 12px;
    height: 12px;
    background: url("../../../static/images/arrow-right.svg") no-repeat;
    background-size: cover;
    transform: translateY(-50%);
  }

  &:last-child {
    padding-right: 0;
  }
}

.breadcrumbItemLink {
  text-decoration: none;
}
