.list {
  position: absolute;
  left: 0;
  top: calc(100% + 1px);
  background-color: var(--secondary-bg);
  border-radius: 4px;
  box-sizing: content-box;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.2);
  z-index: 2;
  list-style-type: none;
  border: 1px solid var(--dark);
  min-width: 100%;

  &  .list {
    left: 100%;
    top: 0;
  }
}

.item {
  text-align: left;
  font-size: 13px;
  position: relative;
  white-space: nowrap;
  width: 100%;
  min-width: 160px;
  cursor: pointer;

  &:hover {
    & > .captionGroup {
      background-color: var(--secondary-bg);
      color: var(--cloudy-blue);
    }
  }

  &:focus {
    outline: none;
  }
}

.captionGroup {
  padding: 6px;
  box-sizing: border-box;
  background-color: var(--dark-two);
  width: 100%;
  position: relative;
  z-index: 1;
}

.text {
  display: inline-block;
  vertical-align: middle;
  width: calc(100% - 16px);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.iconContainer {
  display: inline-block;
  vertical-align: middle;
  width: 16px;
}

.icon {
  font-size: 20px;
  padding: 2px;
  margin-left: 6px;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 2px;
}
